import { reduce, get, isFunction } from "lodash/fp"

const toIdMap = (collection, valueGetter = "name", idGetter = "id") => {
  return reduce(
    (mem, x) => {
      const id = isFunction(idGetter) ? idGetter(x) : get(idGetter, x)
      const value = isFunction(valueGetter)
        ? valueGetter(x)
        : get(valueGetter, x)
      mem[id] = value
      return mem
    },
    {},
    collection
  )
}

export default toIdMap
