import React, {
  memo,
  useMemo,
  useRef,
  useEffect,
  useCallback,
  useState,
} from "react"
import PropTypes from "prop-types"
import { Box, Button } from "grommet"
import UgcGalleryPreviewGroup from "../UgcGalleryPreviewGroup"
import UgcGalleryGroupComponentControl from "../UgcGalleryGroupComponentControl"
import { VariableSizeList as List, areEqual } from "react-window"
import { useWindowSize } from "../../useWindowResize"
import { chunkArray } from "../../../lib/utils"
import Loading from "../../Loading"
import { FormDown, LinkBottom } from "grommet-icons"

const HERO_MODULE_HEGHT_PERC_VIEWPORT = 0.4986
const DEFAULT_ITEM_WIDTH_PERC = 0.3293943217665615
const SIDEBAR_WIDTH = 400
const MODULE_PADDING = 0
const HORIZONTAL_PADDING = 24
const IMAGE_GAP = 3

const UgcGalleryPreview = ({
  items,
  onAction,
  onLoadMore,
  onJumpToBottom,
  hasMore,
  loading,
  replacing,
  galleryLayout,
  handleSave,
}) => {
  const [jumping, setJumping] = useState(false)
  const groupSize = galleryLayout === "salon" ? 7 : 3
  const groups = chunkArray(items, groupSize)
  const isPdp = galleryLayout === "uniform"
  const [windowWidth] = useWindowSize()
  const galleryWindowRef = useRef(null)

  const previewWidth = useMemo(
    () => windowWidth - SIDEBAR_WIDTH - MODULE_PADDING - HORIZONTAL_PADDING,
    [windowWidth]
  )

  const largeHeight = windowWidth * HERO_MODULE_HEGHT_PERC_VIEWPORT + IMAGE_GAP
  const defaultHeight = previewWidth * DEFAULT_ITEM_WIDTH_PERC + IMAGE_GAP
  const itemSize = items.length
  const itemsInModule = galleryLayout === "salon" ? 7 : 9

  const getSize = index => {
    if (isPdp) {
      return defaultHeight
    }
    const isHeroLayout = index % 3 === 0
    const size = isHeroLayout ? largeHeight : defaultHeight
    return size
  }

  useEffect(() => {
    if (galleryWindowRef.current) {
      galleryWindowRef.current.resetAfterIndex(0)
    }
  }, [windowWidth, itemSize, galleryWindowRef])

  const moduleCount = items.length / itemsInModule
  const largeGroupCount = moduleCount // one large per module
  const defaultGroupCount = moduleCount * 2 // two default per module
  const listHeight = useMemo(
    () =>
      isPdp
        ? (itemSize / 3) * defaultHeight
        : largeGroupCount * largeHeight + defaultGroupCount * defaultHeight,
    [
      largeHeight,
      defaultHeight,
      largeGroupCount,
      defaultGroupCount,
      isPdp,
      itemSize,
    ]
  )

  const handleJumpToBottom = useCallback(async () => {
    setJumping(true)
    await onJumpToBottom()
  }, [onJumpToBottom, setJumping])

  const handleItemsRendered = useCallback(() => {
    if (jumping && galleryWindowRef.current) {
      galleryWindowRef.current.scrollTo(listHeight)
      setJumping(false)
    }
  }, [galleryWindowRef, listHeight, jumping])

  const Row = memo(({ index, style }) => {
    return (
      <div style={style}>
        <UgcGalleryPreviewGroup
          key={index}
          index={index}
          items={groups[index]}
          onAction={onAction}
          replacing={replacing}
          galleryLayout={galleryLayout}
        />
      </div>
    )
  }, areEqual)

  Row.displayName = "Row"
  Row.propTypes = {
    index: PropTypes.number.isRequired,
    style: PropTypes.object,
  }

  return (
    <Box direction="column" fill="vertical">
      <Box
        alignContent="start"
        gap="xxsmall"
        flex={{ grow: 1, shrink: 1 }}
        style={{ position: "relative" }}
      >
        {galleryLayout === "salon" && (
          <Box direction="row" wrap={false} gap="xxsmall">
            {groups.map((x, i) => (
              <UgcGalleryPreviewGroup
                key={`group-${i}`}
                index={i}
                items={x}
                onAction={onAction}
                replacing={replacing}
                galleryLayout={galleryLayout}
              />
            ))}
          </Box>
        )}
        {galleryLayout !== "salon" && items.length > 0 && (
          <List
            height={listHeight}
            width="100%"
            itemCount={groups.length}
            itemSize={getSize}
            overscanCount={6}
            ref={galleryWindowRef}
            onItemsRendered={handleItemsRendered}
          >
            {Row}
          </List>
        )}
        {items.length === 0 && (
          <UgcGalleryGroupComponentControl onAction={onAction} items={items} />
        )}
      </Box>

      <Box
        margin="small"
        direction="row-responsive"
        flex={{ shrink: 0 }}
        justify="between"
      >
        {hasMore && (
          <>
            <Button
              disabled={loading || jumping}
              onClick={handleJumpToBottom}
              flex
              label={"Jump to Bottom"}
              icon={<LinkBottom />}
            />
            <Button
              disabled={loading || jumping}
              onClick={onLoadMore}
              flex
              label={loading ? "Loading..." : "Load More"}
              icon={<FormDown />}
            />
          </>
        )}
        <Box />
        <Button
          primary
          flex
          label="Save & Publish"
          icon={loading ? <Loading /> : null}
          disabled={loading || jumping}
          onClick={handleSave}
        />
      </Box>
    </Box>
  )
}

UgcGalleryPreview.propTypes = {
  items: PropTypes.array,
  onAction: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  onJumpToBottom: PropTypes.func.isRequired,
  hasMore: PropTypes.bool,
  loading: PropTypes.bool,
  replacing: PropTypes.bool,
  galleryLayout: PropTypes.string,
  handleSave: PropTypes.func.isRequired,
}

export default UgcGalleryPreview
