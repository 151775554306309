import ky from "ky"
import store from "store"
import { last } from "lodash/fp"

const api = ky.extend({
  hooks: {
    beforeRequest: [
      request => {
        request.headers.set(
          "authorization",
          `Bearer ${
            store.get("token") ? store.get("token").replace(/['"]+/g, "") : null
          }`
        )
        request.headers.set("content-type", `application/json`)
      },
    ],
  },
})

export const requestDownloadUrl = async ({ bucket, key, filename }) => {
  try {
    const downloadUrl = await api.get(
      `${process.env.GATSBY_BACKDROP_API_URL.replace(
        "/graphql",
        ""
      )}/s3/files/${bucket}/${key}?filename=${
        filename || key
      }&disposition=download`
    )

    const { url } = await downloadUrl.json()
    return url
  } catch (e) {
    throw new Error(e)
  }
}

export const extractBucket = url => {
  console.log("BUCKET", url)
  return url
    .split(".s3.amazonaws.com")[0]
    .replace("http://", "")
    .replace("https://", "")
}

export const extractKey = url => {
  return url.split(".s3.amazonaws.com/")[1]
}

export const fallbackExtension = (filename, extension, whitelist = "jpg") => {
  return whitelist.indexOf(last(filename.split("."))) > -1
    ? filename
    : `${filename}.${extension}`
}
