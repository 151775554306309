import * as Yup from "yup"

export const UgcMetaSchema = Yup.object().shape({
  filename: Yup.string(),
  attribution: Yup.string().nullable(),
  notes: Yup.string(),
  tags: Yup.array(),
  placements: Yup.array(),
  galleries: Yup.array(),
  related_products: Yup.array(),
})

export const UgcMetaCompletedSchema = Yup.object().shape({
  filename: Yup.string().required("Filename is required"),
  attribution: Yup.string().required("Attribution is required"),
  notes: Yup.string(),
  tags: Yup.array(),
  placements: Yup.array(),
  galleries: Yup.array(),
  related_products: Yup.array().min(1, "At least one product tag is required"),
})
