import React from "react"
import PropTypes from "prop-types"
import { Button, Box } from "grommet"
import { Add } from "grommet-icons"
import { useSpring, animated } from "react-spring"

const ButtonAddCancel = ({ isAdding, ...rest }) => {
  const AnimatedBox = animated(Box)
  const { transform } = useSpring({
    transform: `rotateZ(${isAdding ? 45 : 0}deg)`,
  })

  return (
    <AnimatedBox style={{ transform }} width="2.8rem">
      <Button size="small" {...rest} icon={<Add size="17px" />} />
    </AnimatedBox>
  )
}

ButtonAddCancel.propTypes = {
  isAdding: PropTypes.bool,
}

export default ButtonAddCancel
