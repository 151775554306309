import React, { useMemo, useReducer, useContext, createContext } from "react"
import PropTypes from "prop-types"

const ShopifyDataContext = createContext({})

const ShopifyDataReducer = (state, action) => {
  const { payload } = action
  switch (action.type) {
    case "SET_PRODUCTS":
      return { ...state, products: payload }
    case "ADD_PRODUCTS":
      return { ...state, products: [...payload, payload] }
    default:
      return state
  }
}

const initialState = {
  products: [],
}

function ShopifyDataProvider(props) {
  const [state, dispatch] = useReducer(
    ShopifyDataReducer,
    props.initialState || initialState
  )
  const value = useMemo(() => [state, dispatch], [state])
  return <ShopifyDataContext.Provider value={value} {...props} />
}

ShopifyDataProvider.propTypes = {
  initialState: PropTypes.object,
}

function useShopifyData() {
  const context = useContext(ShopifyDataContext)
  if (!context) {
    throw new Error("useShopifyData must be used within an ShopifyDataContext")
  }
  const [state, dispatch] = context
  return {
    state,
    dispatch,
  }
}

export { ShopifyDataProvider, useShopifyData }
