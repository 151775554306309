import { gql } from "@apollo/client"

export const GET_COLORS = gql`
  query Colors {
    colors {
      id
      name
      hex_value
      shopify_product_ids
    }
  }
`
