import { get, isArray, isNil } from "lodash/fp"

export const getOr = (key, source, or) => get(key, source) || or

export const toArray = v => (isNil(v) ? v : isArray(v) ? v : [v])

export const isValidDate = d => d instanceof Date && !isNaN(d)

export const initFormDate = d =>
  isValidDate(new Date(d)) ? new Date(d).toISOString() : ""

export const immutableMove = (arr, from, to) => {
  return arr.reduce((prev, current, idx, self) => {
    if (from === to) {
      prev.push(current)
    }
    if (idx === from) {
      return prev
    }
    if (from < to) {
      prev.push(current)
    }
    if (idx === to) {
      prev.push(self[from])
    }
    if (from > to) {
      prev.push(current)
    }
    return prev
  }, [])
}

export const chunkArray = (arr, perChunk) =>
  arr.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk)

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])
