import React, { useMemo, useReducer, useContext, createContext } from 'react';
import GalleryAdminReducer, { initialState } from './GalleryAdminReducer';

const GalleryAdminContext = createContext({});

function GalleryAdminProvider(props) {
  const [state, dispatch] = useReducer(GalleryAdminReducer, initialState);
  const value = useMemo(() => [state, dispatch], [state]);
  return <GalleryAdminContext.Provider value={value} {...props} />;
}

function useGalleryAdmin() {
  const context = useContext(GalleryAdminContext);
  if (!context) {
    throw new Error('useFilters must be used within a GallerAdminProvider');
  }
  const [state, dispatch] = context;
  return {
    state,
    dispatch
  };
}

export { GalleryAdminProvider, useGalleryAdmin };
