import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useDebounce } from "use-debounce"

import InputText from "./InputText"

const InputDebouncedText = ({ field, setFieldValue, onChange, ...rest }) => {
  const [text, setText] = useState(field.value)
  const [value] = useDebounce(text, 1000)

  useEffect(() => {
    if (value !== field.value) {
      setFieldValue(field.name, value)
      if (onChange) {
        onChange(value)
      }
    }
  }, [value])

  return (
    <InputText
      field={{ ...field, value: text }}
      onChange={e => {
        setText(e.target.value)
      }}
      {...rest}
    />
  )
}

InputDebouncedText.propTypes = {
  field: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default InputDebouncedText
