import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { TextInput, Text } from "grommet"
import { Field, ErrorMessage } from "formik"
import Fuse from "fuse.js"
import { map, take, get, isNil } from "lodash/fp"
import styled from "styled-components"

const StyledTextInput = styled(TextInput)`
  font-weight: ${p => p.fontWeight || 400};
`

const searchOptions = {
  threshold: 0.3,
}

const InputTextInner = ({
  field,
  setFieldValue,
  suggestions,
  list,
  onChange,
  plain = true,
  ...rest
}) => {
  const searchMatches = useMemo(
    () =>
      field.value === "" || isNil(field.value)
        ? suggestions
        : map(x => get("item", x), take(8, list.search(field.value))),
    [field.value, list]
  )

  return (
    <>
      <StyledTextInput
        onChange={onChange || field.onChange}
        name={field.name}
        value={field.value}
        onBlur={field.onBlur}
        suggestions={searchMatches}
        onSelect={x => {
          setFieldValue(field.name, x.suggestion)
        }}
        plain={plain}
        {...rest}
      />
      <ErrorMessage name={field.name} component={Text} color="status-error" />
    </>
  )
}

InputTextInner.propTypes = {
  field: PropTypes.object,
  setFieldValue: PropTypes.func,
  suggestions: PropTypes.array,
  list: PropTypes.object,
  onChange: PropTypes.func,
  plain: PropTypes.bool,
}

const InputText = ({ name, icon, autocompleteOptions = [], ...rest }) => {
  const list = useMemo(
    () => new Fuse(autocompleteOptions || [], searchOptions),
    [autocompleteOptions]
  )

  return (
    <Field name={name} icon={icon}>
      {({ field, form: { setFieldValue } }) => (
        <InputTextInner
          field={field}
          setFieldValue={setFieldValue}
          suggestions={autocompleteOptions}
          list={list}
          {...rest}
        />
      )}
    </Field>
  )
}

InputText.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.string,
  autocompleteOptions: PropTypes.array,
}

export default InputText
