import React from "react"
import PropTypes from "prop-types"
import { Box, Menu, Text } from "grommet"
import {
  MoreVertical,
  FormUp,
  FormDown,
  FormTrash,
  FormAdd,
} from "grommet-icons"
import styled from "styled-components"
import { head } from "lodash/fp"

const DividerControl = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999999;
`

const UgcGalleryGroupComponentControl = ({ onAction, items }) => (
  <DividerControl className="divider-control" pad="small">
    <Box
      round="full"
      background="black"
      height="48px"
      width="48px"
      className="divider-button-container"
      elevation="large"
      justify="center"
      align="center"
    >
      <Menu
        icon={<MoreVertical />}
        dropBackground="black"
        dropProps={{ elevation: "large" }}
        size="large"
        items={
          items.length
            ? [
                {
                  label: (
                    <Box direction="row">
                      <FormUp /> <Text>Add module above</Text>
                    </Box>
                  ),
                  onClick: () => {
                    onAction("addModule", {
                      position: "above",
                      target: head(items),
                    })
                  },
                },
                {
                  label: (
                    <Box direction="row">
                      <FormDown /> <Text>Add module below</Text>
                    </Box>
                  ),
                  onClick: () => {
                    onAction("addModule", {
                      position: "below",
                      target: head(items),
                    })
                  },
                },
                {
                  label: (
                    <Box direction="row">
                      <FormTrash /> <Text>Remove module</Text>
                    </Box>
                  ),
                  onClick: () => {
                    onAction("removeModule", {
                      target: head(items),
                    })
                  },
                },
              ]
            : [
                {
                  label: (
                    <Box direction="row">
                      <FormAdd /> <Text>Add module</Text>
                    </Box>
                  ),
                  onClick: () => {
                    onAction("addModule", {
                      position: "above",
                      target: head(items),
                    })
                  },
                },
              ]
        }
      />
    </Box>
  </DividerControl>
)

UgcGalleryGroupComponentControl.propTypes = {
  onAction: PropTypes.func,
  items: PropTypes.array,
}

export default UgcGalleryGroupComponentControl
