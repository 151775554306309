const _ = require("lodash/fp")

const findImageForProduct = (images, productType) => {
  let imageMatcher

  switch (productType) {
    case "Tools":
      imageMatcher = "_web"
      break
    case "Toolskit":
      imageMatcher = "_web"
      break
    case "Supplies":
      imageMatcher = "_web"
      break
    case "Supplies Kit":
      imageMatcher = "_web"
      break
    case "Apparel":
      imageMatcher = "_web"
      break
    case "Giftcard":
      imageMatcher = "_slide"
      break
    case "Standard":
      imageMatcher = "_grid"
      break
    case "Interior Standard":
      imageMatcher = "_grid"
      break
    case "Exterior Standard":
      imageMatcher = "_grid"
      break
    case "Interior Semi-Gloss":
      imageMatcher = "_grid"
      break
    case "Exterior Semi-Gloss":
      imageMatcher = "_grid"
      break
    case "Semigloss":
      imageMatcher = "_grid"
      break
    case "Primer":
      imageMatcher = "_web"
      break
    case "Interior Primer":
      imageMatcher = "_web"
      break
    case "Exterior Primer":
      imageMatcher = "_web"
      break
    default:
      imageMatcher = "_grid"
  }

  const match = _.find(
    x => {
      return _.get("originalSrc", x).indexOf(imageMatcher) > -1
    },
    _.map(y => _.get("node", y), _.get("edges", images, []))
  )

  if (!match) {
    return null
  }

  const url = _.get("originalSrc", match)
  return url
}

module.exports = findImageForProduct
