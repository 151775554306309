import atob from "atob"
import { contains, isNil, isEqual } from "lodash/fp"

const extractGid = (gid, gIdCategory) => {
  if (gid === "_generic" || isNil(gid) || isEqual("undefined", gid)) {
    return gid
  }
  const parsedGid = contains("gid://", gid) ? gid : atob(gid)
  return gIdCategory
    ? parseInt(parsedGid.split(`gid://shopify/${gIdCategory}/`)[1])
    : parsedGid
}

export default extractGid
