import gql from 'graphql-tag';

const PRODUCT_BY_ID = gql`
  query ProductById($id: ID!) {
    node(id: $id) {
      ... on Product {
        handle
        id
        title
        images(first: 20) {
          edges {
            node {
              originalSrc
            }
          }
        }
        variants(first: 4) {
          edges {
            node {
              title
            }
          }
        }
      }
    }
  }
`;

export default PRODUCT_BY_ID;
