import React, { useCallback } from "react"
import PropTypes from "prop-types"
import { useQuery } from "@apollo/react-hooks"
import { get } from "lodash/fp"

import shopifyClient from "../gatsby-theme-apollo/shopifyClient"
import Tag from "./Tag"
import { PRODUCT } from "../queries/products"
import colorByProductId from "../selectors/colorByProductId"

const ProductTag = ({
  id,
  onApprove,
  onReorder,
  onRemove,
  allColors,
  index,
}) => {
  const { data, loading } = useQuery(PRODUCT, {
    variables: { id },
    client: shopifyClient,
  })

  const getBackground = useCallback(
    x => {
      return get("hex_value", colorByProductId(allColors, x))
    },
    [allColors]
  )

  return (
    <Tag
      id={id}
      label={`${get("node.title", data)} - ${get("node.productType", data)}`}
      onRemove={onRemove}
      onApprove={onApprove}
      onReorder={onReorder}
      loading={loading}
      index={index}
      background={getBackground(id)}
    />
  )
}

ProductTag.propTypes = {
  id: PropTypes.string.isRequired,
  onApprove: PropTypes.func,
  onRemove: PropTypes.func.isRequired,
  onReorder: PropTypes.func.isRequired,
  allColors: PropTypes.array.isRequired,
  index: PropTypes.number,
}

export default ProductTag
