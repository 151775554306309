import React, { memo, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Box, Stack, Tip, Text, Button } from "grommet"
import { Trophy } from "grommet-icons"
import { isEqual } from "lodash/fp"

import { setViewingInModal } from "../state/actions/ui"
import FormUgcMeta from "./FormUgcMeta"
import UgcMeta from "./UgcMeta"
import UgcThumbnail from "./UgcThumbnail"
import StarRating from "./StarRating"

const ListItemUgc = ({
  id,
  allProducts,
  allTags,
  allColors,
  allGalleries,
  ugcState,
  layout,
  ...rest
}) => {
  const [editing, setEditing] = useState(isEqual("Pending", ugcState))
  const [returnOnEdit, setReturnOnEdit] = useState(
    isEqual("Processed", ugcState)
  )

  useEffect(() => {
    setEditing(isEqual("Pending", ugcState))
    setReturnOnEdit(isEqual("Processed", ugcState))
  }, [ugcState])

  const handleEdit = () => {
    setEditing(true)
    setReturnOnEdit(true)
  }
  const handleCancel = isEqual("Pending", ugcState)
    ? null
    : () => setEditing(false)

  const handleModalEdit = () => {
    setViewingInModal({
      type: "ugc_image",
      action: "edit",
      data: {
        id,
        file: rest.file,
        title: rest.filename,
        ...rest,
      },
    })
  }

  return (
    <Box
      className="listItemUgc"
      gap="medium"
      justify="stretch"
      direction="row"
      {...rest}
    >
      <Stack anchor="top-left" fill>
        {editing ? (
          <FormUgcMeta
            {...rest}
            id={id}
            allProducts={allProducts}
            allTags={allTags}
            allColors={allColors}
            allGalleries={allGalleries}
            onCancel={handleCancel}
            onSave={returnOnEdit ? () => setEditing(false) : null}
          />
        ) : (
          <Box
            direction={isEqual("list", layout) ? "column" : "row-responsive"}
            className={layout}
            fill
          >
            {isEqual("list", layout) ? (
              <UgcMeta
                {...rest}
                id={id}
                allProducts={allProducts}
                allTags={allTags}
                allColors={allColors}
                allGalleries={allGalleries}
                onEdit={handleEdit}
              />
            ) : (
              <UgcThumbnail
                {...rest}
                id={id}
                allProducts={allProducts}
                allTags={allTags}
                allColors={allColors}
                allGalleries={allGalleries}
                onEdit={handleModalEdit}
              />
            )}
          </Box>
        )}
        {rest.review && (
          <Tip
            content={
              <Text size="xsmall">
                From a review by {rest.review.first_name}{" "}
                {rest.review.last_name}
              </Text>
            }
            dropProps={{
              align: {
                left: "right",
              },
            }}
          >
            <Box margin="xsmall">
              <Button
                style={{
                  background: "rgba(255,255,255,0.7)",
                  padding: "10px",
                  borderRadius: "10px",
                }}
                round="small"
                direction="row"
                gap="xsmall"
                href={`https://backdrop-api.herokuapp.com/admin/plugins/content-manager/collectionType/application::review.review/${rest.review.id}`}
                target="_blank"
                icon={<Trophy size="small" />}
                plain
                label={
                  <StarRating rating={rest.review.rating} starSize="small" />
                }
              />
            </Box>
          </Tip>
        )}
      </Stack>
    </Box>
  )
}

ListItemUgc.propTypes = {
  id: PropTypes.string,
  allProducts: PropTypes.array,
  allTags: PropTypes.array,
  allColors: PropTypes.array,
  allGalleries: PropTypes.array,
  ugcState: PropTypes.string,
  layout: PropTypes.string,
}

export default memo(ListItemUgc)
