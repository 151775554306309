import React from "react"
import PropTypes from "prop-types"
import { Box, Main } from "grommet"

import Header from "./Header"
import "./base.css"

const Layout = ({ children }) => {
  return (
    <Box className="layout" fill>
      <Header />
      <Box flex={{ grow: 1, shrink: 1 }} className="main-container">
        <Main>{children}</Main>
      </Box>
    </Box>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
}

export default Layout
