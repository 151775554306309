import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Box } from "grommet"
import { take, slice } from "lodash/fp"
import UgcGalleryPreviewItem from "../UgcGalleryPreviewItem"
import UgcGalleryGroupComponentControl from "../UgcGalleryGroupComponentControl"

const UgcGalleryPreviewGroupLayoutSalon = ({
  items,
  onAction,
  replacing,
  galleryLayout,
  index,
}) => {
  const isAlternate = index % 2 > 0
  const limited = take(7, items)
  const groupA = take(isAlternate ? 4 : 3, limited)
  const groupB = slice(isAlternate ? 4 : 3, limited.length, limited)
  return (
    <Fragment>
      <UgcGalleryGroupComponentControl onAction={onAction} items={items} />
      <Box direction="row" gap="xxsmall" fill="horizontal">
        <Box
          className="section1"
          direction={isAlternate ? "row" : "column"}
          gap="xxsmall"
          basis={isAlternate ? "55%" : "45%"}
        >
          <Box
            className="section1-1"
            direction={isAlternate ? "column" : "row"}
            gap="xxsmall"
            basis={isAlternate ? "50%" : "45%"}
          >
            {take(2, groupA).map((x, i) => (
              <UgcGalleryPreviewItem
                {...x}
                key={x.id}
                onAction={onAction}
                replacing={replacing}
                basis={isAlternate ? (i === 0 ? "45%" : "55%") : "50%"}
                tagSize="small"
                galleryLayout={galleryLayout}
              />
            ))}
          </Box>
          <Box
            className="section1-2"
            direction="column"
            basis={isAlternate ? "50%" : "55%"}
            gap="xxsmall"
          >
            {slice(isAlternate ? 2 : 2, 4, groupA).map((x, i) => (
              <UgcGalleryPreviewItem
                {...x}
                key={x.id}
                onAction={onAction}
                replacing={replacing}
                basis={i === 0 ? "55%" : "45%"}
                tagSize="small"
              />
            ))}
          </Box>
        </Box>

        <Box
          className="section2"
          gap="xxsmall"
          direction={isAlternate ? "column" : "row"}
          basis={isAlternate ? "45%" : "55%"}
        >
          <Box
            className="section2-1"
            direction={isAlternate ? "row" : "column"}
            gap="xxsmall"
            basis={isAlternate ? "55%" : "50%"}
          >
            {take(isAlternate ? 1 : 2, groupB).map((x, i) => (
              <UgcGalleryPreviewItem
                {...x}
                key={x.id}
                onAction={onAction}
                replacing={replacing}
                basis={isAlternate ? "50%" : i === 0 ? "45%" : "55%"}
                tagSize="small"
              />
            ))}
          </Box>
          <Box
            className="section2-2"
            direction={isAlternate ? "row" : "column"}
            gap="xxsmall"
            basis={isAlternate ? "45%" : "50%"}
          >
            {slice(isAlternate ? 1 : 2, 4, groupB).map((x, i) => (
              <UgcGalleryPreviewItem
                {...x}
                key={x.id}
                onAction={onAction}
                replacing={replacing}
                basis={isAlternate ? "50%" : i === 0 ? "55%" : "45%"}
                tagSize="small"
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Fragment>
  )
}

UgcGalleryPreviewGroupLayoutSalon.propTypes = {
  items: PropTypes.array,
  onAction: PropTypes.func,
  replacing: PropTypes.number,
  galleryLayout: PropTypes.string,
  index: PropTypes.number,
}

export default UgcGalleryPreviewGroupLayoutSalon
