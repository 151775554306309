import React from "react"
import PropTypes from "prop-types"
import { Text } from "grommet"

const LabelForm = ({ label }) => (
  <Text weight={600} size="xsmall">
    {label}
  </Text>
)

LabelForm.propTypes = {
  label: PropTypes.string,
}

export default LabelForm
