import React, { memo } from "react"
import PropTypes from "prop-types"
import { Box, Stack, Text } from "grommet"
import { Checkmark, Share } from "grommet-icons"
import Imgix from "react-imgix"
import { useDrag } from "react-dnd"
import { getClientForUrl, getKeyFromUrl } from "../../../lib/imgixClient"
import moment from "moment"
import styled from "styled-components"

const Meta = styled(Box)`
  span {
    font-size: 12px;
  }
  span.filename {
    font-size: 14px;
  }
`

const UgcListItem = ({
  imageUrl,
  relatedProducts,
  createdAt,
  filename,
  attribution,
  review,
  replacing,
  onAction,
  isSelected,
  id,
}) => {
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: "Ugc",
      ugc: {
        id,
        attribution,
        filename,
        related_products: relatedProducts,
        file: {
          url: imageUrl,
        },
      },
    },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  })
  const client = getClientForUrl(imageUrl || "")
  const imageKey = getKeyFromUrl(imageUrl || "")

  const imgOpts = {
    w: 100,
    h: 100,
    fit: "crop",
    auto: "compress",
    q: "70",
  }
  const src = imageKey ? client.buildURL(imageKey) : undefined

  return (
    <Box direction="row" gap="small">
      <Box flex={{ shrink: 0 }}>
        <Stack anchor={replacing && !isSelected ? "top-right" : "center"}>
          {imageUrl && (
            <div
              style={{ opacity: isSelected ? 0.3 : 1, cursor: "grab" }}
              ref={drag}
            >
              <Imgix
                src={src}
                imgixParams={imgOpts}
                style={{ opacity: isDragging ? 0.4 : 1 }}
                width={100}
                height={100}
              />
            </div>
          )}
          {replacing && !isSelected && (
            <Box
              round={true}
              pad="small"
              background="status-warning"
              elevation="large"
              onClick={() =>
                onAction("confirmReplace", {
                  targetUgc: {
                    id,
                    related_products: relatedProducts,
                    file: { url: imageUrl },
                  },
                  destinationPlacementId: replacing,
                })
              }
            >
              <Share color="black" size="small" />
            </Box>
          )}
          {isSelected && <Checkmark color="status-ok" size="large" />}
        </Stack>
      </Box>
      <Meta>
        <Text
          className="filename"
          truncate={true}
          wordBreak="break-all"
          title={filename}
        >
          {filename}
        </Text>
        <Text color="dark-5">{attribution}</Text>
        <Text color="dark-5">{moment(createdAt).format("M/D/YY")}</Text>
        {review && review.id && (
          <Text color="dark-5">From Review #{review.id}</Text>
        )}
      </Meta>
    </Box>
  )
}

UgcListItem.propTypes = {
  imageUrl: PropTypes.string,
  relatedProducts: PropTypes.array,
  createdAt: PropTypes.string,
  filename: PropTypes.string,
  attribution: PropTypes.string,
  review: PropTypes.object,
  replacing: PropTypes.bool,
  onAction: PropTypes.func,
  placement_id: PropTypes.string,
  isSelected: PropTypes.bool,
  id: PropTypes.string.isRequired,
}

export default memo(UgcListItem)
