import React, { useEffect, useState, memo } from "react"
import PropTypes from "prop-types"
import { useQuery, useMutation } from "@apollo/react-hooks"
import { get, head, reduce, find, map, filter, omit, reject } from "lodash/fp"
import { Box, Select } from "grommet"
import { navigate } from "@reach/router"

import UgcGalleryEditor from "./UgcGallery/UgcGalleryEditor"
import Loading from "./Loading"
import shopifyClient from "../gatsby-theme-apollo/shopifyClient"
import { ALL_PRODUCTS } from "../queries/products"
import { GET_TAGS } from "../queries/tags"
import { GET_COLORS } from "../queries/colors"
import { ALL_GALLERIES, UPDATE_GALLERY_PLACEMENTS } from "../queries/galleries"

const ViewGalleryEditor = ({ galleryId }) => {
  const [, setGalleryError] = useState(null)
  const [galleryOptions, setGalleryOptions] = useState([])
  const { data: tagsData } = useQuery(GET_TAGS)
  const { data: colorsData } = useQuery(GET_COLORS)
  const {
    data: productData,
    loading: productLoading,
    fetchMore: fetchMoreProducts,
  } = useQuery(ALL_PRODUCTS, {
    client: shopifyClient,
  })

  const [updateGallery, { loading }] = useMutation(UPDATE_GALLERY_PLACEMENTS, {
    onError: data => {
      const errorMessage = get("message", head(get("errors", data)))
      setGalleryError({
        message:
          errorMessage || "Something went wrong. Please try again later.",
        json: data,
      })
    },
    awaitRefetchQueries: ["PaintProducts"],
  })

  const { data: galleries, loading: galleriesLoading } = useQuery(
    ALL_GALLERIES,
    {
      onCompleted: d => {
        setGalleryOptions(map("title", get("ugcGalleries", d)))
      },
    }
  )
  const productMap = reduce(
    (mem, x) => {
      mem[get("node.id", x)] = get("node", x)
      return mem
    },
    {},
    get("products.edges", productData)
  )

  useEffect(() => {
    if (!galleryId && !galleriesLoading) {
      navigate(
        `/gallery-editor/${get("id", head(get("ugcGalleries", galleries)))}`,
        { replace: true }
      )
    }
  }, [galleriesLoading, galleries, galleryId])

  useEffect(() => {
    if (productData?.products?.pageInfo?.hasNextPage) {
      fetchMoreProducts({
        variables: {
          after: productData.products.pageInfo.endCursor,
        },
      })
    }
  }, [productData, fetchMoreProducts])

  const activeGallery = find({ id: galleryId }, get("ugcGalleries", galleries))

  const handleGalleryChange = e => {
    map("title", get("ugcGalleries", galleries))
    const selectedId = get(
      "id",
      find({ title: e.value }, get("ugcGalleries", galleries))
    )
    navigate(`/gallery-editor/${selectedId}`, { replace: true })
  }

  const handleSave = async v => {
    const { items, removed, updated, totalPlacements } = v
    const newCount = filter({ isNew: true }, items).length

    const indexedItems = items.map((x, i) => ({
      ...x,
      index: totalPlacements - 1 + newCount - removed.length - i,
    }))
    const newItems = map(x => {
      return {
        ...omit(["isNew", "id", "fit"], x),
        ugc: get("id", x.ugc) ? parseInt(get("id", x.ugc)) : null,
      }
    }, filter({ isNew: true }, indexedItems))
    const filteredRemoved = reject(x => x.indexOf("PLACEHOLDER") > -1, removed)

    const fullUpdated = map(
      x => omit(["__typename", "isNew"], { ...x, ugc: get("id", x.ugc) }),
      filter(
        x => updated.indexOf(x.id) > -1 && x.id.indexOf("PLACEHOLDER") === -1,
        reject(
          z => removed.indexOf(z) > -1,
          reject({ isNew: true }, indexedItems)
        )
      )
    )

    updateGallery({
      variables: {
        input: {
          updated: fullUpdated,
          removed: filteredRemoved,
          newItems,
          galleryId,
        },
      },
    })
  }

  return (
    <Box fill className="editor">
      {productLoading || galleriesLoading ? (
        <Box fill justify="center" align="center" pad="large">
          <Loading size="large" />
        </Box>
      ) : (
        <>
          <Box flex={{ shrink: 1 }}>
            <Select
              value={get("title", activeGallery)}
              options={galleryOptions}
              onChange={handleGalleryChange}
              onSearch={text => {
                const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&")
                const exp = new RegExp(escapedText, "i")
                if (text === "" || !text) {
                  setGalleryOptions(
                    map("title", get("ugcGalleries", galleries))
                  )
                } else {
                  setGalleryOptions(
                    map("title", get("ugcGalleries", galleries)).filter(o =>
                      exp.test(o)
                    )
                  )
                }
              }}
            />
          </Box>
          {galleryId && (
            <Box
              flex={{ grow: 1, shrink: 1 }}
              fill
              className="editor-container"
              height={{ max: "calc(100vh - 123px)" }}
            >
              <UgcGalleryEditor
                productMap={productMap}
                gallery={activeGallery}
                galleryLayout={get("layout", activeGallery)}
                allProducts={get("products.edges", productData)}
                allTags={get("tags", tagsData)}
                allColors={get("colors", colorsData)}
                onSave={handleSave}
                loading={loading || galleriesLoading}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  )
}

ViewGalleryEditor.propTypes = {
  galleryId: PropTypes.string.isRequired,
  queryParams: PropTypes.object,
}

export default memo(ViewGalleryEditor)
