import React, { memo } from "react"
import PropTypes from "prop-types"
import ListItemUgc from "./ListItemUgc"
import { Box, Grid } from "grommet"
import { isEqual } from "lodash/fp"

import Loading from "./Loading"

const ListUgc = ({
  items = [],
  allProducts = [],
  allTags = [],
  allColors = [],
  allGalleries = [],
  loading = false,
  ugcState,
  ugcLayout,
}) => (
  <>
    {loading ? (
      <Box fill align="center" justify="center">
        <Loading size="large" />
      </Box>
    ) : (
      <>
        {isEqual("list", ugcLayout) && (
          <Box gap="small">
            {items.map(x => (
              <ListItemUgc
                key={x.id}
                {...x}
                allProducts={allProducts}
                allTags={allTags}
                allColors={allColors}
                allGalleries={allGalleries}
                ugcState={ugcState}
                border={{ side: "bottom", color: "light-3", size: "1px" }}
                pad={{ bottom: "small" }}
                layout={ugcLayout}
              />
            ))}
          </Box>
        )}
        {isEqual("thumb", ugcLayout) && (
          <Grid
            className="grid"
            gap="small"
            fill
            columns={{ count: 3, size: "flex" }}
            style={{ gridAutoRows: "calc(100vw/3)" }}
          >
            {items.map(x => (
              <ListItemUgc
                key={x.id}
                {...x}
                allProducts={allProducts}
                allTags={allTags}
                allColors={allColors}
                allGalleries={allGalleries}
                ugcState={ugcState}
                layout={ugcLayout}
              />
            ))}
          </Grid>
        )}
      </>
    )}
  </>
)

ListUgc.propTypes = {
  items: PropTypes.array,
  allProducts: PropTypes.array,
  allTags: PropTypes.array,
  allColors: PropTypes.array,
  allGalleries: PropTypes.array,
  loading: PropTypes.bool,
  ugcState: PropTypes.string,
  ugcLayout: PropTypes.string,
}

export default memo(ListUgc)
