import { getClientForUrl, getKeyFromUrl } from "./imgixClient"

const generateCropUrl = (src, cropParams) => {
  const client = getClientForUrl(src)
  return client.buildURL(getKeyFromUrl(src), {
    rect: cropParams.rect.join(","),
    q: 100,
  })
}

export default generateCropUrl
