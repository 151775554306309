import React from "react"
import PropTypes from "prop-types"
import { Box } from "grommet"

import UgcImagePreview from "./UgcImagePreview"

const UgcThumbnail = ({ id, filename, onEdit, ...rest }) => {
  return (
    <Box direction="row-responsive" className="ugc-thumbnail" fill>
      <UgcImagePreview {...rest} id={id} filename={filename} onEdit={onEdit} />
    </Box>
  )
}

UgcThumbnail.propTypes = {
  id: PropTypes.string.isRequired,
  filename: PropTypes.string,
  onEdit: PropTypes.func,
}

export default UgcThumbnail
