import React, { useCallback, useState, memo } from "react"
import PropTypes from "prop-types"
import ReactCrop from "react-image-crop"
import { Box, Button } from "grommet"
import { get, map } from "lodash/fp"
import "react-image-crop/dist/ReactCrop.css"
import styled from "styled-components"
import Spinner from "../../Loading"

const StyledBox = styled(Box)`
  .ReactCrop__image {
    max-height: 80vh;
  }
`

const UgcGalleryImageCropper = ({ item, onCrop }) => {
  const { rect = [0, 0, 0, 0] } = item
  const safeRect = useCallback(r => (r ? r : []), [])
  const [loadedImage, setLoadedImage] = useState(null)
  const [crop, setCrop] = useState({
    x: safeRect(rect)[0],
    y: safeRect(rect)[1],
    width: safeRect(rect)[2],
    height: safeRect(rect)[3],
  })
  const [cropParams, setCropParams] = useState({})
  const onImageLoaded = useCallback(
    image => {
      if (!loadedImage) {
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
        setLoadedImage(image)
        const updated = {
          x: safeRect(rect)[0] / scaleX,
          y: safeRect(rect)[1] / scaleY,
          width: safeRect(rect)[2] / scaleX,
          height: safeRect(rect)[3] / scaleY,
        }
        setCrop(updated)
      }
      return false
    },
    [loadedImage, safeRect, rect]
  )
  const onCropComplete = useCallback(
    crop => {
      const { width, height, x, y } = crop
      if (loadedImage) {
        const scaleX =
          (get("naturalWidth", loadedImage) || loadedImage.width) /
          loadedImage.width
        const scaleY =
          (get("naturalHeight", loadedImage) || loadedImage.height) /
          loadedImage.height

        const result = {
          rect: map(Math.round, [
            x * scaleX,
            y * scaleY,
            width * scaleX,
            height * scaleY,
          ]),
        }
        setCropParams(result)
      }
    },
    [loadedImage]
  )

  return (
    <StyledBox fill align="center">
      <ReactCrop
        src={get("ugc.file.url", item)}
        disabled={!loadedImage}
        crop={crop}
        onChange={setCrop}
        onComplete={onCropComplete}
        onImageLoaded={onImageLoaded}
      />
      {loadedImage ? (
        <Box gap="small">
          <Button
            primary
            label="Save"
            onClick={() => onCrop(item.id, cropParams)}
          />
          <Button label="Cancel" onClick={() => onCrop(null)} />
        </Box>
      ) : (
        <Box>
          <Spinner />
        </Box>
      )}
    </StyledBox>
  )
}

UgcGalleryImageCropper.propTypes = {
  item: PropTypes.object,
  onCrop: PropTypes.func.isRequired,
}

export default memo(UgcGalleryImageCropper)
