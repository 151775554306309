import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useQuery } from "@apollo/react-hooks"
import { navigate } from "gatsby"

import { GET_CURRENT_USER } from "../queries/auth"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { data, loading } = useQuery(GET_CURRENT_USER)

  useEffect(() => {
    if (!loading && (!data || !data.token) && location.pathname !== `/login`) {
      navigate("/login")
    }
  }, [data, loading])

  return <Component {...rest} />
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
  location: PropTypes.object,
}
export default PrivateRoute
