import ImgixClient from "imgix-core-js"

const client = new ImgixClient({
  domain: "backdrop-reviews.imgix.net",
})

export const staticClient = new ImgixClient({
  domain: "backdrop-static.imgix.net",
})

export const getClientForUrl = url =>
  url.indexOf("backdrop-static-content") > -1 ? staticClient : client

export const getKeyFromUrl = url =>
  url
    ? decodeURIComponent(url)
        .replace("https://backdrop-reviews.s3.amazonaws.com/", "")
        .replace("https://backdrop-static-content.s3.amazonaws.com/", "")
    : undefined

export default client
