import store from "store"
import { currentUserVar, tokenVar } from "../variables/auth"

export const setCurrentUser = data => {
  store.set("user", data)
  currentUserVar(data)
}
export const setToken = async data => {
  await store.set("token", data)
  tokenVar(data)
}
export const logout = () => {
  store.remove("token")
  store.remove("user")
  tokenVar(null)
  currentUserVar(null)
}
