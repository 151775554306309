import { gql } from "@apollo/client"

export const UgcFragment = gql`
  fragment ClientUgc on Ugc {
    id
    file {
      url
    }
    related_products
    filename
    attribution
    alt_text
    createdAt
    updatedAt
    notes
    processed
    dropbox_file_id
    tags {
      id
    }
    placements {
      id
      ugc_gallery {
        id
        title
      }
    }
    review {
      id
      first_name
      last_name
      rating
    }
    _deleted @client
  }
`

export const LIST_UGC = gql`
  query ListUgc(
    $skip: Int
    $limit: Int
    $filename: String
    $attribution: String
    $related_products: [String]!
    $tags: [ID]!
    $galleryId: ID
    $galleries: [ID]
    $isProcessed: Boolean
    $isPublished: Boolean
    $createdBefore: DateTime
    $createdAfter: DateTime
  ) {
    countUgc(
      filename: $filename
      attribution: $attribution
      related_products: $related_products
      tags: $tags
      galleryId: $galleryId
      galleries: $galleries
      isPublished: $isPublished
      isProcessed: $isProcessed
      createdBefore: $createdBefore
      createdAfter: $createdAfter
    ) {
      count
    }
    listUgc(
      filename: $filename
      attribution: $attribution
      related_products: $related_products
      tags: $tags
      skip: $skip
      limit: $limit
      galleryId: $galleryId
      galleries: $galleries
      isPublished: $isPublished
      isProcessed: $isProcessed
      createdBefore: $createdBefore
      createdAfter: $createdAfter
    ) {
      id
      file {
        __typename
        url
        mime
        id
      }
      related_products
      insertion_source
      filename
      attribution
      alt_text
      createdAt
      updatedAt
      notes
      processed
      dropbox_file_id
      inserted_by {
        id
        username
      }
      tags {
        id
      }
      placements {
        id
        ugc_gallery {
          id
          title
        }
      }
      review {
        id
        first_name
        last_name
        rating
      }
      _deleted @client
    }
  }
`

export const GET_UGC_COUNTS = gql`
  query UgcCounts {
    processed: ugcsConnection(where: { processed: true }) {
      aggregate {
        count
      }
    }
    pending: ugcsConnection(where: { processed: false }) {
      aggregate {
        count
      }
    }
    all: ugcsConnection {
      aggregate {
        count
      }
    }
  }
`

export const UPDATE_UGC = gql`
  mutation UpdateUgc($input: updateUgcAndPlaceInGalleriesInput) {
    updateUgcAndPlaceInGalleries(input: $input) {
      ugc {
        id
        file {
          url
        }
        related_products
        filename
        attribution
        alt_text
        createdAt
        updatedAt
        notes
        processed
        dropbox_file_id
        tags {
          id
        }
        placements {
          id
          ugc_gallery {
            id
            title
          }
        }
        review {
          id
          first_name
          last_name
          rating
        }
        _deleted @client
      }
    }
  }
`

export const CREATE_UGC_FROM_FILES = gql`
  mutation CreateUgcAndFiles($input: createUgcFromFilesInput) {
    createUgcFromFiles(input: $input) {
      ugcs {
        id
        file {
          url
        }
        related_products
        filename
        attribution
        updatedAt
        alt_text
        dropbox_file_id
        tags {
          id
        }
        review {
          id
        }
        __typename
        _deleted @client
      }
    }
  }
`

export const GET_UGC = gql`
  query GetUgc($id: ID!) {
    ugc(id: $id) {
      id
      file {
        url
      }
      related_products
      filename
      attribution
      alt_text
      createdAt
      updatedAt
      notes
      processed
      dropbox_file_id
      tags {
        id
      }
      placements {
        id
        ugc_gallery {
          id
          title
        }
      }
      review {
        id
        first_name
        last_name
        rating
      }
      _deleted @client
    }
  }
`

export const DELETE_UGC = gql`
  mutation DeleteUgc($input: deleteUgcInput) {
    deleteUgc(input: $input) {
      ugc {
        id
      }
    }
  }
`
