import React, { useMemo, useReducer, useContext, createContext } from 'react';
import GalleryPickerReducer, { initialState } from './GalleryPickerReducer';

const GalleryPickerContext = createContext({});

function GalleryPickerProvider(props) {
  const [state, dispatch] = useReducer(GalleryPickerReducer, initialState);
  const value = useMemo(() => [state, dispatch], [state]);
  return <GalleryPickerContext.Provider value={value} {...props} />;
}

function usePicker() {
  const context = useContext(GalleryPickerContext);
  if (!context) {
    throw new Error('useFilters must be used within a GallerPickerProvider');
  }
  const [state, dispatch] = context;

  const nextPage = () => dispatch({ type: 'NEXT_PAGE' });
  const prevPage = () => dispatch({ type: 'PREV_PAGE' });
  const setUgcQuery = payload => dispatch({ type: 'SET_UGC_QUERY', payload });
  const setLoadedUgc = payload => dispatch({ type: 'SET_LOADED_UGC', payload });
  const addSelectedUgc = payload =>
    dispatch({ type: 'ADD_SELECTED_UGC', payload });

  return {
    state,
    dispatch,
    nextPage,
    prevPage,
    setUgcQuery,
    setLoadedUgc,
    addSelectedUgc
  };
}

export { GalleryPickerProvider, usePicker };
