import React from "react"
import PropTypes from "prop-types"
import { Box, Heading, Layer, Button } from "grommet"
import { Close } from "grommet-icons"

const Modal = ({ children, title, headerActions = [], onClose }) => {
  return (
    <Layer animation="fadeIn" onEsc={onClose} onClickOutside={onClose}>
      <Box
        direction="row"
        justify={title ? "between" : "end"}
        align="center"
        pad="small"
        gap="xsmall"
      >
        <Box direction="row" gap="xsmall">
          {headerActions.map(x => (
            <Button
              key={x.label}
              icon={x.icon}
              label={x.label}
              onClick={x.onClick}
            />
          ))}
        </Box>
        {title && (
          <Box flex={{ grow: 1 }}>
            <Heading margin="none" level={4} truncate={true}>
              {title}
            </Heading>
          </Box>
        )}
        <Box
          onClick={onClose}
          justify="end"
          align="end"
          className="close-container"
        >
          <Close />
        </Box>
      </Box>
      <Box height={{ max: "80vh" }} overflow="auto" width={{ min: "medium" }}>
        {children}
      </Box>
    </Layer>
  )
}

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string,
  headerActions: PropTypes.array,
  onClose: PropTypes.func.isRequired,
}

export default Modal
