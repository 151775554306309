import React, { useCallback, useState, useEffect, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { useLazyQuery } from "@apollo/react-hooks"
import { Box, TextInput, Drop } from "grommet"
import { Field } from "formik"
import { get, map } from "lodash/fp"

import shopifyClient from "../gatsby-theme-apollo/shopifyClient"
import { SEARCH_PRODUCTS } from "../queries/products"
import LabelForm from "./LabelForm"
import ButtonAddCancel from "./ButtonAddCancel"
import extractGid from "../lib/extractGid"
import ProductTag from "./ProductTag"

const InputProducts = ({
  onReorder,
  onApprove,
  onRemove,
  allColors,
  label,
  name,
  suggestions = [],
}) => {
  const addButton = useRef()
  const [isAdding, setIsAdding] = useState(false)
  const [searchQuery, setSearchQuery] = useState()
  const [searchProducts, { data: productData }] = useLazyQuery(
    SEARCH_PRODUCTS,
    {
      client: shopifyClient,
      fetchPolicy: "no-cache",
    }
  )

  const searchResults = map("node", get("products.edges", productData))

  const handleSearchProducts = useCallback(
    event => {
      setSearchQuery(event.target.value)
    },
    [searchQuery]
  )

  useEffect(() => {
    if (searchQuery && searchQuery !== "") {
      searchProducts({ variables: { query: searchQuery } })
    }
  }, [searchQuery])

  const productResults = useMemo(() => {
    return !searchQuery || searchQuery === ""
      ? []
      : map(
          x => ({
            value: extractGid(x.id),
            label: `${x.title} - ${x.productType}`,
          }),
          searchResults
        )
  }, [searchResults])

  return (
    <Box>
      <LabelForm label={label} />
      <Box direction="row" wrap={true} ref={addButton}>
        <Field name={name}>
          {({ field }) => {
            return (
              <Box
                direction={onReorder ? "column" : "row"}
                wrap={true}
                gap="xsmall"
                pad={{ vertical: "xsmall", horizontal: "small" }}
              >
                {field.value.map((x, i) => {
                  return (
                    <ProductTag
                      key={x}
                      id={x}
                      label={x}
                      onRemove={onRemove}
                      allColors={allColors}
                      onReorder={onReorder}
                      index={i}
                    />
                  )
                })}
                {suggestions.map((x, i) => (
                  <ProductTag
                    key={x}
                    id={x}
                    label={`${x.title} - ${x.productType}`}
                    onRemove={onRemove}
                    allColors={allColors}
                    onApprove={onApprove}
                    index={i}
                  />
                ))}

                <ButtonAddCancel
                  isAdding={isAdding}
                  onClick={() => setIsAdding(!isAdding)}
                />
              </Box>
            )
          }}
        </Field>
      </Box>
      {isAdding && (
        <Drop
          target={addButton.current}
          onClickOutside={() => setIsAdding(false)}
          onEsc={() => setIsAdding(false)}
          elevation="none"
          align={{ top: "bottom" }}
        >
          <Box pad={{ horizontal: "small", vertical: "xsmall" }}>
            <TextInput
              autoFocus={true}
              suggestions={productResults}
              value={searchQuery}
              onChange={handleSearchProducts}
              onSelect={e => {
                onApprove(e.suggestion.value)
                setSearchQuery("")
              }}
            />
          </Box>
        </Drop>
      )}
    </Box>
  )
}

InputProducts.propTypes = {
  onChange: PropTypes.func,
  onReorder: PropTypes.func,
  onApprove: PropTypes.func,
  onRemove: PropTypes.func,
  allColors: PropTypes.array,
  productsSource: PropTypes.object,
  productOptions: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  suggestions: PropTypes.array,
}

export default InputProducts
