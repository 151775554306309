import { gql } from "@apollo/client"

export const ALL_PRODUCTS = gql`
  query AllProducts($after: String) {
    products(first: 250, after: $after) {
      edges {
        cursor
        node {
          id
          title
          productType
          images(first: 10) {
            edges {
              node {
                transformedSrc
                originalSrc
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`

export const SEARCH_PRODUCTS = gql`
  query SearchProducts($query: String!) {
    products(first: 10, query: $query) {
      edges {
        cursor
        node {
          id
          title
          productType
          images(first: 10) {
            edges {
              node {
                transformedSrc
                originalSrc
              }
            }
          }
        }
      }
    }
  }
`

export const PRODUCT = gql`
  query ProductById($id: ID!) {
    node(id: $id) {
      id
      __typename
      ... on Product {
        handle
        id
        title
        description
        productType
      }
    }
  }
`
