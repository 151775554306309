import React, { memo, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Box, Collapsible, Button, Text } from "grommet"
import { FormUp, FormDown } from "grommet-icons"
import UgcGalleryItemPicker from "../UgcGalleryItemPicker"
import DisplacedUgc from "../DisplacedUgc"
import FilterSummary from "../../FilterSummary"
import { get } from "lodash/fp"
import { LIST_UGC } from "../../../queries/ugc"
import { useLazyQuery } from "@apollo/react-hooks"

import UgcFilterControls from "../../UgcFilterControls"
import Pagination from "../../Pagination"

const UgcGalleryPicker = ({
  handleImageAction,
  replacing,
  chosenUgc,
  removedUgc,
  allGalleries,
  allTags,
  allColors,
  allProducts,
}) => {
  const perPage = 30
  const [filtersOpen, setFiltersOpen] = useState(false)
  const [params, setParams] = useState({
    related_products: [],
    tags: [],
    limit: perPage,
    page: 1,
    isProcessed: true,
  })

  const [getUgc, { loading, data }] = useLazyQuery(LIST_UGC)

  useEffect(() => {
    getUgc({
      variables: {
        ...params,
        skip: ((params.page || 1) - 1) * perPage,
        limit: perPage,
      },
    })
  }, [params])

  const handleFilterChange = variables => {
    setParams({ ...variables, page: 1, isProcessed: true })
  }

  const handlePage = v => {
    setParams({
      ...params,
      page: v,
    })
  }
  return (
    <Box direction="column" fill="vertical">
      <Box flex={{ grow: 1, shrink: 0 }}>
        <Box
          direction="row"
          justify="between"
          border={{ side: "bottom", size: "small", color: "dark-5" }}
        >
          <Box
            pad="small"
            direction="row"
            gap="xsmall"
            justify="center"
            align="center"
          >
            <Text>Filters</Text>
            {!filtersOpen && (
              <Box animation="fadeIn">
                <FilterSummary
                  params={params}
                  allProducts={allProducts}
                  allTags={allTags}
                  allColors={allColors}
                />
              </Box>
            )}
          </Box>
          <Button
            icon={filtersOpen ? <FormUp /> : <FormDown />}
            onClick={() => setFiltersOpen(!filtersOpen)}
          />
        </Box>
        <Collapsible open={filtersOpen}>
          <UgcFilterControls
            allProducts={allProducts}
            allTags={allTags}
            allColors={allColors}
            allGalleries={allGalleries}
            ugcState="Processed"
            stacked={true}
            onSubmit={handleFilterChange}
            queryParams={params}
            background="white"
          />
        </Collapsible>
        <Pagination
          margin={{ vertical: "small" }}
          totalCount={get("countUgc.count", data)}
          page={params.page}
          perPage={perPage}
          onPage={handlePage}
        />
      </Box>
      {get("listUgc", data) && (
        <UgcGalleryItemPicker
          onPage={handlePage}
          onAction={handleImageAction}
          items={get("listUgc", data)}
          loading={loading}
          replacing={replacing}
          selected={chosenUgc}
          removed={removedUgc}
        />
      )}
      <DisplacedUgc replacing={replacing} onAction={handleImageAction} />
    </Box>
  )
}

UgcGalleryPicker.propTypes = {
  handleImageAction: PropTypes.func,
  replacing: PropTypes.bool,
  tagFilters: PropTypes.array,
  galleryid: PropTypes.string,
  chosenUgc: PropTypes.array,
  removedUgc: PropTypes.array,
  title: PropTypes.string,
  allGalleries: PropTypes.array,
  allTags: PropTypes.array,
  allColors: PropTypes.array,
  allProducts: PropTypes.array,
}

export default memo(UgcGalleryPicker)
