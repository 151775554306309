import { gql } from "@apollo/client"

export const LOGIN = gql`
  mutation Login($input: UsersPermissionsLoginInput!) {
    login(input: $input) {
      token
      user {
        id
        email
        confirmed
        blocked
        role {
          id
          name
          description
          type
        }
      }
    }
  }
`
export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser @client
    token @client
  }
`
