import React, { useEffect, useMemo, useRef, memo } from "react"
import PropTypes from "prop-types"
import { useLazyQuery, useQuery } from "@apollo/react-hooks"
import { useReactiveVar } from "@apollo/client"
import { navigate } from "@reach/router"
import { Box } from "grommet"
import { Share, Download } from "grommet-icons"
import { isEqual, get, reject, toLower, omit } from "lodash/fp"
import qs from "query-string"

import shopifyClient from "../gatsby-theme-apollo/shopifyClient"
import ListUgc from "./ListUgc"
import Modal from "./Modal"
import DisplayImageFull from "./DisplayImageFull"
import HeaderPendingAssets from "./HeaderPendingAssets"
import DuplicateAndCrop from "./DuplicateAndCrop"
import UgcFilterControls from "./UgcFilterControls"
import FormUgcMeta from "./FormUgcMeta"
import Pagination from "./Pagination"
import { LIST_UGC } from "../queries/ugc"
import { GET_FILTER_STATE } from "../queries/filter"
import { ALL_PRODUCTS } from "../queries/products"
import { GET_TAGS } from "../queries/tags"
import { GET_GALLERIES } from "../queries/galleries"
import { GET_COLORS } from "../queries/colors"
import {
  viewingInModalVar,
  croppingVar,
  ugcLayoutVar,
} from "../state/variables/ui"
import { setCropping, setUgcLayout } from "../state/actions/ui"
import { getOr } from "../lib/utils"
import {
  requestDownloadUrl,
  extractBucket,
  extractKey,
  fallbackExtension,
} from "../lib/s3"

import generateFilterQueryString from "../state/actions/generateFilterQueryString"

const getQueryVariables = (queryParams, isPending, perPage, ugcState) => ({
  skip: isPending ? 0 : (getOr("page", queryParams, 1) - 1) * perPage,
  limit: perPage,
  tags: isPending ? [] : getOr("tags", queryParams, []),
  galleries: isPending ? [] : getOr("galleries", queryParams, []),
  filename: isPending ? undefined : getOr("filename", queryParams, undefined),
  attribution: isPending
    ? undefined
    : getOr("attribution", queryParams, undefined),
  related_products: isPending ? [] : getOr("related_products", queryParams, []),
  isProcessed: isEqual("All", ugcState) ? null : !isPending,
  createdBefore: isPending
    ? undefined
    : isEqual("", get("createdBefore", queryParams))
    ? undefined
    : get("createdBefore", queryParams),
  createdAfter: isPending
    ? undefined
    : isEqual("", get("createdAfter", queryParams))
    ? undefined
    : get("createdAfter", queryParams),
})

const ViewManage = ({ ugcState, queryParams }) => {
  const [getUgc, { data }] = useLazyQuery(LIST_UGC)
  useQuery(GET_FILTER_STATE)
  const { data: tagsData, loading: tagsLoading } = useQuery(GET_TAGS)
  const { data: colorsData, loading: colorsLoading } = useQuery(GET_COLORS)
  const { data: galleriesData, loading: galleriesLoading } = useQuery(
    GET_GALLERIES
  )
  const { data: productData, fetchMore: fetchMoreProducts } = useQuery(
    ALL_PRODUCTS,
    {
      client: shopifyClient,
    }
  )
  const inModal = useReactiveVar(viewingInModalVar)
  const inCropper = useReactiveVar(croppingVar)
  const ugcLayout = useReactiveVar(ugcLayoutVar)
  const isPaginatedView =
    isEqual(ugcState, "Processed") || isEqual(ugcState, "All")

  const isPending = isEqual(ugcState, "Pending")
  const perPage = isPending ? 10 : 30

  const listRef = useRef()

  useEffect(() => {
    getUgc({
      variables: getQueryVariables(queryParams, isPending, perPage, ugcState),
      fetchPolicy: "cache-and-network",
    })
    if (listRef.current) {
      if (window.pageYOffset > listRef.current.offsetTop) {
        window.scrollTo(0, listRef.current.offsetTop)
      }
    }
  }, [queryParams])

  useEffect(() => {
    if (productData?.products?.pageInfo?.hasNextPage) {
      fetchMoreProducts({
        variables: {
          after: productData.products.pageInfo.endCursor,
        },
      })
    }
  }, [productData, fetchMoreProducts])

  const filteredItems = useMemo(
    () =>
      reject(
        x =>
          (isPaginatedView ? false : get("processed", x)) || get("_deleted", x),
        get("listUgc", data)
      ),
    [data]
  )

  const handlePageChange = page => {
    navigate(
      generateFilterQueryString({ ...queryParams, page }, { replace: true })
    )
  }

  const handleViewOriginal = () => {
    window.open(get("data.file.url", inModal), "_blank")
  }

  const handleDownload = async () => {
    const bucket = extractBucket(get("data.file.url", inModal))
    const key = extractKey(get("data.file.url", inModal))
    const url = await requestDownloadUrl({
      bucket,
      key,
      filename: fallbackExtension(get("data.title", inModal), "jpg"),
    })
    window.location = url
  }

  const handleDisplayChange = layout => {
    setUgcLayout(layout)
  }

  const handleFilterSubmit = async values => {
    const filterUrl = generateFilterQueryString({
      tags: get("tags", values),
      galleries: get("galleries", values),
      related_products: get("related_products", values),
      filename: get("filename", values),
      attribution: get("attribution", values),
      createdBefore: get("createdBefore", values),
      createdAfter: get("createdAfter", values),
      page: 1,
    })
    navigate(filterUrl, { replace: true })
  }

  return (
    <Box fill pad="medium">
      <UgcFilterControls
        margin={{ vertical: "small" }}
        allProducts={get("products.edges", productData)}
        allTags={get("tags", tagsData)}
        allColors={get("colors", colorsData)}
        allGalleries={get("ugcGalleries", galleriesData)}
        queryParams={queryParams}
        ugcState={ugcState}
        onDisplayChange={isPaginatedView ? handleDisplayChange : null}
        onStateChange={value =>
          navigate(
            `/${toLower(value)}?${
              isEqual("Pending", value)
                ? ""
                : qs.stringify(omit("page", queryParams))
            }`
          )
        }
        ugcLayout={ugcLayout}
        title="Uploads"
        onSubmit={handleFilterSubmit}
      />
      <Box ref={listRef}>
        {isEqual(ugcState, "Pending") && (
          <HeaderPendingAssets
            ugcState={ugcState}
            margin={{ bottom: "medium" }}
            queryVariables={getQueryVariables(queryParams, isPending, perPage)}
          />
        )}
        {isPaginatedView && (
          <Pagination
            margin={{ vertical: "small" }}
            totalCount={get("countUgc.count", data)}
            page={parseInt(getOr("page", queryParams, 1))}
            perPage={perPage}
            onPage={handlePageChange}
          />
        )}
        <ListUgc
          items={filteredItems}
          allProducts={get("products.edges", productData)}
          allTags={get("tags", tagsData)}
          allColors={get("colors", colorsData)}
          allGalleries={get("ugcGalleries", galleriesData)}
          loading={tagsLoading || colorsLoading || galleriesLoading}
          ugcState={ugcState}
          ugcLayout={isEqual("Pending", ugcState) ? "list" : ugcLayout}
        />
        {isPaginatedView && (
          <Pagination
            margin={{ vertical: "small" }}
            totalCount={get("countUgc.count", data)}
            page={parseInt(getOr("page", queryParams, 1))}
            perPage={perPage}
            onPage={handlePageChange}
          />
        )}
      </Box>
      {get("data.id", inModal) && (
        <Modal
          headerActions={[
            { icon: <Share />, onClick: handleViewOriginal },
            { icon: <Download />, onClick: handleDownload },
          ]}
          title={get("data.title", inModal)}
          onClose={() => viewingInModalVar({})}
        >
          {isEqual("view", get("action", inModal)) && (
            <DisplayImageFull {...get("data", inModal)} />
          )}
          {isEqual("edit", get("action", inModal)) && (
            <FormUgcMeta
              {...get("data", inModal)}
              allProducts={get("products.edges", productData)}
              allTags={get("tags", tagsData)}
              allColors={get("colors", colorsData)}
              allGalleries={get("ugcGalleries", galleriesData)}
              onCancel={() => viewingInModalVar({})}
              onSave={() => viewingInModalVar({})}
            />
          )}
        </Modal>
      )}
      {get("ugc", inCropper) && (
        <Modal onClose={() => setCropping({})} title="Duplicate & Crop">
          <DuplicateAndCrop
            ugc={get("ugc", inCropper)}
            modifiedValues={get("values", inCropper)}
            onComplete={() => setCropping({})}
            onClose={() => setCropping({})}
            queryVariables={getQueryVariables(queryParams, isPending, perPage)}
          />
        </Modal>
      )}
    </Box>
  )
}

ViewManage.propTypes = {
  ugcState: PropTypes.string,
  queryParams: PropTypes.object,
}

export default memo(ViewManage)
