import React, { memo } from "react"
import PropTypes from "prop-types"
import { Box } from "grommet"
import { useDrop } from "react-dnd"

const GalleryPreviewContainer = ({ children, galleryLayout, onAction }) => {
  const [, drop] = useDrop({
    accept: ["UgcPlacement", "Ugc"],
    drop: item => {
      onAction("drop", { item })
    },
    canDrop: () => galleryLayout === "uniform",
    collect: mon => ({
      isOver: !!mon.isOver() && galleryLayout === "alternating_hero",
    }),
  })

  return (
    <Box flex={{ grow: 1, shrink: 1 }} ref={drop} fill id="viewer" pad="4px">
      {children}
    </Box>
  )
}

GalleryPreviewContainer.propTypes = {
  children: PropTypes.node,
  galleryLayout: PropTypes.string,
  onAction: PropTypes.func.isRequired,
}

export default memo(GalleryPreviewContainer)
