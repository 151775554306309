import React, { memo } from "react"
import PropTypes from "prop-types"
import { Box, Heading } from "grommet"
import { useDisplacedUgc } from "./useDisplacedUgc"
import UgcListItem from "../UgcListItem"
import { get } from "lodash/fp"

const ListDisplacedUgc = ({ onAction, replacing }) => {
  const {
    state: { displacedUgc },
  } = useDisplacedUgc()

  if (displacedUgc.length === 0) {
    return null
  }

  return (
    <Box
      height={{ max: "medium" }}
      pad="small"
      elevation="large"
      animation="slideUp"
      background="light-5"
      border={{ size: "small", color: "light-5", side: "all" }}
      gap="small"
    >
      <Heading level={5} margin="none">
        Displaced UGC ({displacedUgc.length})
      </Heading>
      <Box overflow="auto">
        <Box gap="xsmall" flex={false}>
          {displacedUgc.map(x => (
            <UgcListItem
              key={x.id}
              altText={x.alt_text}
              filename={get("filename", x)}
              attribution={get("attribution", x)}
              createdAt={get("createdAt", x)}
              imageUrl={get("file.url", x)}
              review={get("review", x)}
              placement_id={get("placement_id", x)}
              relatedProducts={get("related_products", x)}
              replacing={replacing}
              onAction={onAction}
              isSelected={false}
              id={x.id}
            />
          ))}
        </Box>
      </Box>
    </Box>
  )
}

ListDisplacedUgc.propTypes = {
  onAction: PropTypes.func,
  replacing: PropTypes.bool,
}

export default memo(ListDisplacedUgc)
