import React, { Fragment, memo } from "react"
import PropTypes from "prop-types"
import { Box, Image, Text, Heading } from "grommet"
import { useQuery } from "@apollo/react-hooks"
import { get } from "lodash/fp"
import shopifyClient from "../../../gatsby-theme-apollo/shopifyClient"
import productById from "../../../queries/productById"
import findImageForProduct from "../../../lib/findImageForProduct"
import styled from "styled-components"

const TagText = styled(Text)`
  font-weight: 500;
  letter-spacing: 1px;
  font-size: ${p => (p.size === "small" ? "8px" : "11px")};
  & > * {
    font-weight: 500;
  }
`

const OverlayHeading = styled(Heading)`
  font-size: 5vw;
  line-height: 5vw;
  @media only screen ${p =>
      p.theme.global.breakpoints.small.value &&
      `and (max-width: ${p.theme.global.breakpoints.small.value}px)`} {
    font-size: 6vw;
    line-height: 7.4vw;
  }
`

const ProductTag = ({ large, data, tagSize }) => {
  const image = findImageForProduct(get("images", data), "Interior Standard")
  const isSmall = tagSize === "small"
  const blobSize = isSmall ? "15px" : "30px"

  return (
    <Fragment>
      {large ? (
        <OverlayHeading textAlign="center" color="white">
          {get("title", data)}
        </OverlayHeading>
      ) : (
        <Box
          background="white"
          border={{ side: "all", color: "black", size: "small" }}
          pad={{
            vertical: isSmall ? "xxsmall" : "xsmall",
            horizontal: isSmall ? "xsmall" : "small",
          }}
          direction="row"
          gap="xsmall"
          align="center"
          flex={false}
        >
          <Box height={blobSize} width={blobSize} flex={{ shrink: 0, grow: 0 }}>
            <Image src={image} fill />
          </Box>
          <Box flex={{ shrink: 1, grow: 1 }}>
            <TagText size={isSmall ? "small" : "medium"}>
              {get("title", data)}
            </TagText>
          </Box>
        </Box>
      )}
    </Fragment>
  )
}

ProductTag.propTypes = {
  large: PropTypes.object,
  data: PropTypes.object,
  tagSize: PropTypes.string,
}

export const ProductTagFetched = ({ id, large, tagSize }) => {
  const graphQLId = btoa(id)
  const { data, loading, error } = useQuery(productById, {
    client: shopifyClient,
    variables: { id: graphQLId },
  })

  if (loading) return "Loading..."
  if (error) return `Error! ${error.message}`

  return (
    <Fragment>
      <ProductTag data={get("node", data)} large={large} tagSize={tagSize} />
    </Fragment>
  )
}

ProductTagFetched.propTypes = {
  large: PropTypes.object,
  id: PropTypes.string,
  tagSize: PropTypes.string,
}

export default memo(ProductTag)
