import React from "react"
import PropTypes from "prop-types"
import Uppy from "@uppy/core"
import AwsS3 from "@uppy/aws-s3"
import { Dashboard, useUppy } from "@uppy/react"
import { pick } from "lodash/fp"

import "@uppy/core/dist/style.css"
import "@uppy/dashboard/dist/style.css"

const InputFileUpload = ({ handleSubmit, setFieldValue, setStatus, name }) => {
  const uppy = useUppy(() => {
    return new Uppy()
      .use(AwsS3, {
        getUploadParameters: file => {
          // Send a request to our PHP signing endpoint.
          return fetch("/.netlify/functions/s3-sign", {
            method: "post",
            // Send and receive JSON.
            headers: {
              accept: "application/json",
              "content-type": "application/json",
            },
            body: JSON.stringify({
              filename: file.name,
              contentType: file.type,
              path: "asset-manager/uploads",
            }),
          })
            .then(response => {
              // Parse the JSON response.
              return response.json()
            })
            .then(data => {
              // Return an object in the correct shape.
              return {
                method: data.method,
                url: data.url,
                fields: data.fields || {},
                headers: {
                  "Content-Type": file.type,
                },
              }
            })
        },
      })
      .on("upload", () => {
        setStatus({ uploading: true })
      })
      .on("complete", result => {
        const uploadedFiles = result.successful.map(x => {
          return {
            ...pick(
              ["name", "type", "uploadURL", "id", "extension", "size"],
              x
            ),
            key: x.meta.key,
          }
        })
        setFieldValue(name, uploadedFiles)
        setStatus({ uploading: false })
        handleSubmit()
      })
  })

  return (
    <Dashboard
      uppy={uppy}
      locale={{
        strings: {
          dropPaste:
            "Upload your photos or videos by dropping them here, or %{browse} for them",
        },
      }}
      width="100%"
      height="300px"
      proudlyDisplayPoweredByUppy={false}
    />
  )
}

InputFileUpload.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
}

export default InputFileUpload
