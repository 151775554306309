import { gql } from "@apollo/client"

export const GET_GALLERIES = gql`
  query Galleries {
    ugcGalleries {
      id
      title
    }
  }
`

export const NON_UNIFORM_GALLERIES = gql`
  query NonUniformGalleries {
    ugcGalleries(where: { layout_ne: "uniform" }) {
      id
      updatedAt
      title
      identifier
      placementsUpdatedAt
      layout
      tag_filters {
        id
        name
      }
    }
  }
`

export const ALL_GALLERIES = gql`
  query AllGalleries {
    ugcGalleries(sort: "title") {
      id
      updatedAt
      title
      identifier
      placementsUpdatedAt
      layout
      tag_filters {
        id
        name
      }
    }
  }
`

export const UPDATE_GALLERY_PLACEMENTS = gql`
  mutation UpdateGalleryPlacements($input: UpdateGalleryPlacementsInput) {
    updateGalleryPlacements(input: $input) {
      updatedAt
    }
  }
`
