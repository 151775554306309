import React, { memo } from "react"
import PropTypes from "prop-types"
import { Box } from "grommet"
import RatingStar from "./RatingStar"

const StarRating = ({
  rating,
  outOf = 5,
  starSize,
  color,
  gap = "small",
  ...rest
}) => {
  const isDecimalRating = rating % 1 > 0
  const stars = new Array(outOf)
    .fill(false)
    .map((x, i) => (i < rating ? true : false))
  return (
    <Box direction="row" gap={gap} {...rest}>
      {stars.map((x, i, arr) => (
        <RatingStar
          size={starSize}
          key={i}
          color={color}
          active={x}
          decimal={i === arr.length - 1 && isDecimalRating ? rating % 1 : 0}
        />
      ))}
    </Box>
  )
}

StarRating.propTypes = {
  outOf: PropTypes.number,
  rating: PropTypes.number,
  gap: PropTypes.string,
  starSize: PropTypes.string,
  color: PropTypes.string,
}

export default memo(StarRating)
