import React from "react"
import PropTypes from "prop-types"
import { Heading } from "grommet"

const FormLabel = ({ text }) => (
  <Heading
    level={6}
    size="small"
    color="dark-2"
    style={{ minWidth: "5em" }}
    margin={{ horizontal: "small", vertical: "none" }}
  >
    {text}
  </Heading>
)

FormLabel.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default FormLabel
