import React from "react"
import PropTypes from "prop-types"
import { DateInput } from "grommet"

const InputDatePicker = ({ field, form, onChange, ...rest }) => {
  return (
    <DateInput
      format="mm/dd/yyyy"
      onChange={e => {
        onChange(e.value) || form.setFieldValue(field.name, e.value)
      }}
      value={field.value ? new Date(field.value).toISOString() : field.value}
      {...rest}
    />
  )
}

InputDatePicker.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default InputDatePicker
