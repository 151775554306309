import React, { useState, memo } from "react"
import PropTypes from "prop-types"
import { Edit, StatusGood } from "grommet-icons"
import { Box, Stack, Button } from "grommet"
import { get } from "lodash/fp"

import ImgixImage from "./ImgixImage"
import { setViewingInModal } from "../state/actions/ui"

const UgcImagePreview = ({
  file,
  filename,
  processed,
  id,
  onEdit,
  ...rest
}) => {
  const [hovering, setHovering] = useState(false)
  return (
    <Box
      fill
      background="light-1"
      flex={{ shrink: 0, grow: 1 }}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      overflow="hidden"
      onClick={() => {
        setViewingInModal({
          type: "ugc_image",
          action: "view",
          data: {
            id,
            file: file,
            title: filename,
          },
        })
      }}
    >
      <Stack fill anchor="top-right">
        <Box fill>
          <ImgixImage
            fit="cover"
            src={get("url", file)}
            params={{
              w: 800,
              ar: "1:1",
              crop: "entropy",
              fit: "crop",
              auto: "format",
            }}
          />
        </Box>
        {processed && (
          <Box
            margin="small"
            round="full"
            align="center"
            justify="center"
            textAlign="center"
          >
            <StatusGood color="white" />
          </Box>
        )}
        {onEdit && hovering && (
          <Box
            background="white"
            animation={{
              type: "slideDown",
              duration: 300,
              size: "xlarge",
            }}
            round={{ size: "small", corner: "bottom-left" }}
          >
            <Button
              icon={
                <Edit
                  color="black"
                  onClick={e => {
                    e.stopPropagation()
                    onEdit({ file, filename, id, ...rest })
                  }}
                />
              }
            />
          </Box>
        )}
      </Stack>
    </Box>
  )
}

UgcImagePreview.propTypes = {
  file: PropTypes.object,
  filename: PropTypes.string,
  processed: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onEdit: PropTypes.func,
}

export default memo(UgcImagePreview)
