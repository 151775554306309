import React from "react"
import PropTypes from "prop-types"
import { Box } from "grommet"
import UgcGalleryPreviewItem from "../UgcGalleryPreviewItem"

const UgcGalleryPreviewGroupLayoutUniform = ({
  items,
  onAction,
  replacing,
  galleryLayout,
}) => (
  <Box direction="row" gap="xxsmall" fill="horizontal">
    {items.map(x => (
      <UgcGalleryPreviewItem
        {...x}
        key={x.id}
        onAction={onAction}
        replacing={replacing}
        height="100%"
        galleryLayout={galleryLayout}
      />
    ))}
  </Box>
)

UgcGalleryPreviewGroupLayoutUniform.propTypes = {
  items: PropTypes.array,
  onAction: PropTypes.func,
  replacing: PropTypes.bool,
  galleryLayout: PropTypes.string,
}

export default UgcGalleryPreviewGroupLayoutUniform
