import React, { useCallback } from "react"
import PropTypes from "prop-types"
import { Box } from "grommet"
import { get, map } from "lodash/fp"

import colorByProductId from "../selectors/colorByProductId"
import Tag from "./Tag"

const TagList = ({
  tags,
  dataSource,
  useProductColor = true,
  allColors = [],
  round,
  ...rest
}) => {
  const getBackground = useCallback(
    x =>
      useProductColor
        ? get("hex_value", colorByProductId(allColors, x))
        : undefined,
    [allColors, useProductColor]
  )

  const tagIds = map(x => get("id", x) || x, tags)

  return (
    <Box
      direction="row"
      wrap={true}
      gap="xsmall"
      pad={{ vertical: "xsmall", horizontal: "small" }}
      {...rest}
    >
      {tagIds.map((x, i) =>
        dataSource[x] ? (
          <Tag
            key={x || i}
            id={x}
            index={i}
            label={dataSource[x]}
            background={getBackground(x)}
            round={round}
          />
        ) : (
          <></>
        )
      )}
    </Box>
  )
}

TagList.propTypes = {
  tags: PropTypes.array,
  dataSource: PropTypes.object,
  useProductColor: PropTypes.bool,
  allColors: PropTypes.array,
  round: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}

export default TagList
