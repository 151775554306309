import React, { memo } from "react"
import PropTypes from "prop-types"
import { Box } from "grommet"

import UgcList from "../UgcList"

const UgcGalleryItemPicker = ({
  onPage,
  items,
  loading,
  replacing,
  onAction,
  selected,
  removed,
}) => {
  return (
    <Box
      overflow="auto"
      fill="vertical"
      pad="small"
      border={{ size: "small", side: "top", color: "light-5" }}
    >
      <UgcList
        items={items}
        onPage={onPage}
        loading={loading}
        replacing={replacing}
        onAction={onAction}
        selected={selected}
        removed={removed}
      />
    </Box>
  )
}

UgcGalleryItemPicker.propTypes = {
  onPage: PropTypes.func,
  items: PropTypes.array,
  loading: PropTypes.bool,
  replacing: PropTypes.bool,
  onAction: PropTypes.func,
  selected: PropTypes.array,
  removed: PropTypes.array,
}

export default memo(UgcGalleryItemPicker)
