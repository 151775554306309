import React from "react"
import PropTypes from "prop-types"
import { Blank } from "grommet-icons"

const IconStar = ({ active, decimal, color, ...props }) => {
  return (
    <Blank viewBox="2 2 20 20" {...props}>
      <defs>
        <linearGradient id={`gradient-${decimal}`}>
          <stop
            offset={`${decimal === 0 ? "100" : decimal * 100}%`}
            stopColor={color}
          />
          <stop
            offset={`${100 - decimal * 100}%`}
            stopColor="white"
            stopOpacity="1"
          />
        </linearGradient>
      </defs>
      {active ? (
        <>
          <path
            fill={`url(#gradient-${decimal})`}
            d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
          />
          {decimal > 0 && (
            <path d="M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z" />
          )}
        </>
      ) : (
        <path d="M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z" />
      )}
    </Blank>
  )
}

IconStar.propTypes = {
  active: PropTypes.bool,
  decimal: PropTypes.number,
  color: PropTypes.string,
}

export default IconStar
