import React, { useMemo, useReducer, useContext, createContext } from 'react';
import { reject } from 'lodash/fp';

const DisplacedUgcContext = createContext({});

const DisplacedUgcReducer = (state, action) => {
  switch (action.type) {
    case 'SET_DISPLACED_UGC':
      return { ...state, displacedUgc: action.payload };
    case 'ADD_DISPLACED_UGC':
      return {
        ...state,
        displacedUgc: [...state.displacedUgc, action.payload]
      };
    case 'REMOVE_DISPLACED_UGC':
      return {
        ...state,
        displacedUgc: reject({ id: action.payload }, state.displacedUgc)
      };
    case 'UPDATE_DISPLACED_UGC':
      return {
        ...state,
        displacedUgc: reject(
          { id: action.payload.placed.id },
          action.payload.displaced
            ? [...state.displacedUgc, action.payload.displaced]
            : state.displacedUgc
        )
      };
    default:
      return state;
  }
};

const initialState = {
  displacedUgc: []
};

function DisplacedUgcProvider(props) {
  const [state, dispatch] = useReducer(DisplacedUgcReducer, initialState);
  const value = useMemo(() => [state, dispatch], [state]);
  return <DisplacedUgcContext.Provider value={value} {...props} />;
}

function useDisplacedUgc() {
  const context = useContext(DisplacedUgcContext);
  if (!context) {
    throw new Error('useFilters must be used within a ShopifyProductsProvider');
  }
  const [state, dispatch] = context;

  const setDisplacedUgc = payload =>
    dispatch({ type: 'SET_DISPLACED_UGC', payload });
  const addDisplacedUgc = payload =>
    dispatch({ type: 'ADD_DISPLACED_UGC', payload });
  const removeDisplacedUgc = payload =>
    dispatch({ type: 'REMOVE_DISPLACED_UGC', payload });
  const updateDisplacedUgc = payload =>
    dispatch({ type: 'UPDATE_DISPLACED_UGC', payload });

  return {
    state,
    dispatch,
    setDisplacedUgc,
    addDisplacedUgc,
    removeDisplacedUgc,
    updateDisplacedUgc
  };
}

export { DisplacedUgcProvider, useDisplacedUgc };
