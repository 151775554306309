import React, { useContext } from "react"
import { useQuery } from "@apollo/react-hooks"
import { get } from "lodash/fp"
import {
  Header as GrommetHeader,
  Box,
  Menu,
  Text,
  ResponsiveContext,
  Nav,
} from "grommet"
import { Logout, Gallery, Grid } from "grommet-icons"

import Loading from "./Loading"
import InternalAnchor from "./InternalAnchor"
import { GET_CURRENT_USER } from "../queries/auth"
import { logout as actionLogout } from "../state/actions/auth"

const Header = () => {
  const { loading, data } = useQuery(GET_CURRENT_USER)
  const size = useContext(ResponsiveContext)
  const logout = () => {
    actionLogout()
  }
  return (
    <GrommetHeader
      background="brand"
      pad={{ vertical: "xsmall", horizontal: "small" }}
    >
      <Box
        direction="row"
        gap="medium"
        flex={false}
        align="center"
        justify="center"
      >
        <Box direction="row" gap="xsmall" justify="center" align="center">
          <Text weight={500}>BACKDROP</Text>
          <Text color="light-5" size="small">
            Assets
          </Text>
        </Box>
        <Nav direction="row" pad="small">
          <InternalAnchor
            to="/pending"
            style={{ color: "white" }}
            activeStyle={{ color: "red" }}
            icon={<Gallery size="16px" />}
            label="UGC"
            size="small"
          />
          <InternalAnchor
            to="/gallery-editor/510"
            style={{ color: "white" }}
            activeStyle={{ color: "red" }}
            icon={<Grid size="16px" />}
            label="Gallery Editor"
            size="small"
          />
        </Nav>
      </Box>
      {loading && <Loading />}
      {data && (
        <Menu
          label={size === "small" ? undefined : get("currentUser.email", data)}
          items={[
            {
              label: "logout",
              onClick: logout,
              justify: "center",
              align: "center",
              size: "small",
              icon: <Logout />,
              gap: "small",
            },
          ]}
          dropAlign={{ right: "right", top: "bottom" }}
        />
      )}
    </GrommetHeader>
  )
}

export default Header
