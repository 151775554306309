import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Box } from "grommet"
import styled from "styled-components"
import { map, reject } from "lodash/fp"

import UgcGalleryPreviewItem from "../UgcGalleryPreviewItem"
import UgcGalleryGroupComponentControl from "../UgcGalleryGroupComponentControl"

const LargeGroupContainer = styled(Box)`
  flex-direction: ${p => (p.reverse ? "row-reverse" : "row")};
  height: 49.86vw;
`

const LargeItemContainer = styled(Box)`
  width: ${p => p.percentageWidth}%;
`

const UgcGalleryPreviewGroupLayoutHero = ({
  heroItem,
  onAction,
  items,
  alternateHero,
  replacing,
  galleryLayout,
}) => (
  <Fragment>
    <UgcGalleryGroupComponentControl onAction={onAction} items={items} />
    <LargeGroupContainer
      direction="row"
      fill="horizontal"
      className={`${heroItem ? "large" : "medium"}-group`}
      gap="xxsmall"
      background="white"
    >
      <Box
        gap="xxsmall"
        direction={alternateHero ? "row-reverse" : "row"}
        fill="horizontal"
      >
        <LargeItemContainer
          fill="vertical"
          className="large-ugc-items"
          basis={"auto"}
          percentageWidth={60}
        >
          <UgcGalleryPreviewItem
            {...heroItem}
            key={heroItem.id}
            height="100%"
            gap="xxsmall"
            onAction={onAction}
            large={heroItem}
            replacing={replacing}
            galleryLayout={galleryLayout}
          />
        </LargeItemContainer>
        <Box flex="grow" className="small-ugc-items" gap="2px" width="40%">
          {map(
            x => (
              <UgcGalleryPreviewItem
                {...x}
                key={x.id}
                inLargeGroup={true}
                onAction={onAction}
                replacing={replacing}
                height="49.86vw"
              />
            ),
            reject({ id: heroItem.id }, items)
          )}
        </Box>
      </Box>
    </LargeGroupContainer>
  </Fragment>
)

UgcGalleryPreviewGroupLayoutHero.propTypes = {
  onAction: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  alternateHero: PropTypes.bool,
  replacing: PropTypes.bool,
  heroIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  isHeroLayout: PropTypes.bool,
  galleryLayout: PropTypes.string,
  heroItem: PropTypes.object.isRequired,
}

export default UgcGalleryPreviewGroupLayoutHero
