export const PAINT_TYPES = [
  "Interior Standard",
  "Interior Semi-Gloss",
  "Exterior Standard",
  "Exterior Semi-Gloss",
  "Primer",
]

export const APPAREL_TYPES = ["Apparel"]

export const isPaintType = type => PAINT_TYPES.indexOf(type) > -1
export const isApparelType = type => APPAREL_TYPES.indexOf(type) > -1
