import React from "react"
import PropTypes from "prop-types"
import { useMutation } from "@apollo/react-hooks"
import { Box } from "grommet"
import { Formik } from "formik"
import InputFileUpload from "./InputFileUpload"
import Loading from "./Loading"

import { CREATE_UGC_FROM_FILES, LIST_UGC } from "../queries/ugc"

const FormUploadAssets = ({ onSuccess, queryVariables }) => {
  const [createUgc] = useMutation(CREATE_UGC_FROM_FILES, {
    refetchQueries: ["UgcCounts"],
    update: (
      cache,
      {
        data: {
          createUgcFromFiles: { ugcs },
        },
      }
    ) => {
      const data = cache.readQuery({
        query: LIST_UGC,
        variables: queryVariables,
      })
      cache.writeQuery({
        query: LIST_UGC,
        variables: queryVariables,
        data: {
          ...data,
          listUgc: [...data.listUgc, ...ugcs],
        },
      })
    },
  })
  return (
    <Formik
      initialValues={{}}
      onSubmit={async (values, { setSubmitting, setFieldError, setStatus }) => {
        setSubmitting(true)
        try {
          setStatus({ working: true })
          await createUgc({ variables: { input: values } })
          onSuccess()
        } catch (e) {
          setFieldError("identifier", "Email or password is incorrect")
        } finally {
          setStatus({ working: false })
          setSubmitting(false)
        }
      }}
    >
      {formProps => {
        return (
          <Box>
            {formProps.status && formProps.status.working ? (
              <Box height="300px" fill align="center" justify="center">
                <Loading />
              </Box>
            ) : (
              <InputFileUpload {...formProps} name="files" />
            )}
          </Box>
        )
      }}
    </Formik>
  )
}

FormUploadAssets.propTypes = {
  onSuccess: PropTypes.func,
  queryVariables: PropTypes.object,
}

export default FormUploadAssets
