import React, { useEffect } from "react"
import { useQuery } from "@apollo/react-hooks"
import { navigate } from "gatsby"
import { Box } from "grommet"
import FormLogin from "./FormLogin"
import { GET_CURRENT_USER } from "../queries/auth"
import { setCurrentUser, setToken } from "../state/actions/auth"

const ViewLogin = () => {
  const { data } = useQuery(GET_CURRENT_USER)

  useEffect(() => {
    if (data && data.token) {
      navigate("/pending")
    }
  }, [data])

  const handleSuccess = async data => {
    await setCurrentUser(data.user)
    await setToken(data.token)
    window.location.reload()
  }

  return (
    <Box height="80vh" align="center" justify="center">
      <Box width="medium">
        <FormLogin onSuccess={handleSuccess} />
      </Box>
    </Box>
  )
}

export default ViewLogin
