import React, { useState } from "react"
import PropTypes from "prop-types"
import Uppy from "@uppy/core"
import AwsS3 from "@uppy/aws-s3"
import { useUppy } from "@uppy/react"
import { useMutation } from "@apollo/react-hooks"
import { get, isEqual, pick } from "lodash/fp"
import imageToBlob from "image-to-blob"

import { CREATE_UGC_FROM_FILES, LIST_UGC } from "../queries/ugc"
import ImageCropper from "./ImageCropper"
import generateCropUrl from "../lib/generateCropUrl"

const DuplicateAndCrop = ({
  ugc,
  modifiedValues = {},
  onComplete,
  onClose,
  queryVariables,
}) => {
  // Create UGC from files
  const [uploading, setUploading] = useState(false)
  const [createUgc] = useMutation(CREATE_UGC_FROM_FILES, {
    refetchQueries: ["UgcCounts"],
    update: (
      cache,
      {
        data: {
          createUgcFromFiles: { ugcs },
        },
      }
    ) => {
      const data = cache.readQuery({
        query: LIST_UGC,
        variables: queryVariables,
      })
      cache.writeQuery({
        query: LIST_UGC,
        variables: queryVariables,
        data: {
          ...data,
          listUgc: [...data.listUgc, ...ugcs],
        },
      })
    },
  })

  const uppy = useUppy(() => {
    return new Uppy()
      .use(AwsS3, {
        getUploadParameters: file => {
          return fetch("/.netlify/functions/s3-sign", {
            method: "post",
            headers: {
              accept: "application/json",
              "content-type": "application/json",
            },
            body: JSON.stringify({
              filename: file.name,
              contentType: file.type,
              path: "asset-manager/uploads",
            }),
          })
            .then(response => {
              return response.json()
            })
            .then(data => {
              // Return an object in the correct shape.
              return {
                method: data.method,
                url: data.url,
                fields: data.fields || {},
                headers: {
                  "Content-Type": file.type,
                },
              }
            })
        },
      })
      .on("upload", () => {
        setUploading(true)
      })
      .on("complete", result => {
        setUploading(false)
        const uploadedFiles = result.successful.map(x => {
          return {
            ...pick(["name", "type", "uploadURL", "extension", "size"], x),
            key: x.meta.key,
            name: `COPY-${get("filename", modifiedValues)}`,
            related_products: get("related_products", modifiedValues),
            tags: get("tags", modifiedValues),
            notes: get("notes", modifiedValues),
          }
        })
        createUgc({
          variables: {
            input: {
              files: uploadedFiles,
            },
          },
        })
      })
  })

  const handleCrop = async cropParams => {
    setUploading(true)
    if (isEqual({}, cropParams)) {
      return onComplete()
    }
    const url = generateCropUrl(get("file.url", ugc), cropParams)

    imageToBlob(url, async (err, blob) => {
      await uppy.addFile({
        name: `COPY-${get("filename", modifiedValues)}`,
        type: get("file.mime", ugc),
        data: blob,
      })
      await uppy.upload()
      onComplete({})
    })
  }

  return (
    <ImageCropper
      src={get("file.url", ugc)}
      onCrop={handleCrop}
      onCancel={onClose}
      loading={uploading}
    />
  )
}

DuplicateAndCrop.propTypes = {
  onComplete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  ugc: PropTypes.object.isRequired,
  modifiedValues: PropTypes.object.isRequired,
  queryVariables: PropTypes.object.isRequired,
}

export default DuplicateAndCrop
