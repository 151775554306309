import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Box, Button, Text, TextInput, ThemeContext } from "grommet"
import { Next, Previous } from "grommet-icons"

const Pagination = ({ totalCount, page, perPage, onPage, ...rest }) => {
  const limit = perPage
  const [totalPages, setTotalPages] = useState(
    totalCount ? Math.ceil(totalCount / limit) : 0
  )
  const theme = useContext(ThemeContext)
  useEffect(() => {
    if (totalCount) {
      setTotalPages(totalCount ? Math.ceil(totalCount / limit) : 0)
    }
  }, [page, totalCount, limit])

  return (
    <Box {...rest} direction="row" justify="end" align="center">
      <Button
        secondary
        size="small"
        icon={<Previous size="small" />}
        onClick={() => onPage(parseInt(page) - 1)}
        disabled={parseInt(page) === 1}
        title="Previous"
      />
      <Box direction="row" gap="small" justify="center" align="center">
        <Text size="xsmall">Page</Text>
        <Box>
          <TextInput
            plain
            size="xsmall"
            name="page"
            type="number"
            value={page}
            onChange={e => {
              onPage(e.target.value)
            }}
            min={1}
            max={totalPages || 1}
            width="small"
            style={{
              width: "3rem",
              padding: 0,
              textAlign: "center",
              background: theme.global.colors["light-2"],
            }}
          />
        </Box>
        {totalPages > 0 && <Text size="xsmall">of {totalPages}</Text>}
      </Box>
      <Button
        secondary
        size="small"
        reverse
        icon={<Next size="small" />}
        onClick={() => onPage(parseInt(page) + 1)}
        disabled={parseInt(page) === totalPages}
        title="Next"
      />
    </Box>
  )
}

Pagination.propTypes = {
  totalCount: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  onPage: PropTypes.number,
}

export default Pagination
