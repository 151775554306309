import React from "react"
import PropTypes from "prop-types"
import { Box, Tip, Text } from "grommet"
import { Clock, Camera } from "grommet-icons"
import { map, get, find, isEqual } from "lodash/fp"
import { format } from "date-fns"

import colorByProductId from "../selectors/colorByProductId"
import Tag from "./Tag"

const FilterSummary = ({ params, allTags, allColors }) => {
  const {
    related_products,
    tags,
    filename,
    attribution,
    createdAfter,
    createdBefore,
  } = params
  const productColors = map(
    x => colorByProductId(allColors, x),
    related_products
  )
  return (
    <Box direction="row" wrap={true} gap="6px" justify="center" align="center">
      {map(
        x => (
          <Tip key={x.id} content={<Text size="small">{x.name}</Text>}>
            <Box
              round={true}
              border={{ side: "all", color: "dark-5", size: "1px" }}
              height="16px"
              width="16px"
              background={x.hex_value}
            ></Box>
          </Tip>
        ),
        productColors
      )}
      {map(x => {
        const tag = find({ id: String(x) }, allTags)
        return (
          <Tag
            key={tag.id}
            pad={{ vertical: "2px", horizontal: "4px" }}
            label={get("name", tag)}
            id={tag}
            margin="none"
            textSize={"12px"}
            border={{ side: "all", color: "dark-5", size: "1px" }}
          />
        )
      }, tags)}
      {filename && !isEqual("", filename) && (
        <Text truncate={true} size="xsmall">
          &ldquo;{filename}&rdquo;
        </Text>
      )}
      {attribution && !isEqual("", attribution) && (
        <Box direction="row" justify="center" align="center" gap="4px">
          <Camera size="14px" />
          <Text size="12px">{attribution}</Text>
        </Box>
      )}
      {(createdBefore || createdAfter) && (
        <Tip
          content={
            <Text size="xsmall">
              {createdBefore &&
                createdAfter &&
                `Created between ${format(
                  new Date(createdAfter),
                  "M-d-yyyy"
                )} and ${format(new Date(createdBefore), "M-d-yyyy")}`}
              {createdBefore &&
                !createdAfter &&
                `Created before ${format(new Date(createdBefore), "M-d-yyyy")}`}
              {createdAfter &&
                !createdBefore &&
                `Created after ${format(new Date(createdAfter), "M-d-yyyy")}`}
            </Text>
          }
        >
          <Box>
            <Clock size="16px" />
          </Box>
        </Tip>
      )}
    </Box>
  )
}

FilterSummary.propTypes = {
  params: PropTypes.shape({
    related_products: PropTypes.array,
    tags: PropTypes.array,
    filename: PropTypes.string,
    attribution: PropTypes.string,
    createdAfter: PropTypes.string,
    createdBefore: PropTypes.string,
  }),
  allTags: PropTypes.array,
  allColors: PropTypes.array,
}

export default FilterSummary
