import React, { useContext } from "react"
import PropTypes from "prop-types"
import { Anchor, ThemeContext } from "grommet"
import { Link } from "gatsby"

const InternalAnchor = ({ label, icon, size = "medium", to }) => {
  const theme = useContext(ThemeContext)
  return (
    <Link
      to={to}
      style={{
        textDecoration: theme.anchor.textDecoration,
      }}
      partiallyActive={true}
      activeStyle={{ textDecoration: theme.anchor.hover.textDecoration }}
    >
      <Anchor
        as="span"
        gap="xsmall"
        size={size}
        icon={icon}
        hoverIndicator
        label={label}
        style={{ textDecoration: "inherit" }}
      />
    </Link>
  )
}

InternalAnchor.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.node,
  size: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default InternalAnchor
