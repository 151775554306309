import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Box, Text } from "grommet"
import { useQuery } from "@apollo/react-hooks"
import { get } from "lodash/fp"
import { Dropbox } from "grommet-icons"

import Loading from "./Loading"
import { GET_UGC } from "../queries/ugc"

const UgcDropboxStatus = ({ id, dropbox_file_id }) => {
  const { data, stopPolling } = useQuery(GET_UGC, {
    variables: { id },
    skip: dropbox_file_id,
    pollInterval: 2000,
  })

  useEffect(() => {
    if (get("ugc.dropbox_file_id", data)) {
      stopPolling()
    }
  }, [get("ugc.dropbox_file_id", data)])

  return (
    <Box>
      {dropbox_file_id ? (
        <Box direction="row" gap="xsmall" align="center">
          <Dropbox size="18px" />
          <Text size="xxsmall">Synced to Dropbox</Text>
        </Box>
      ) : (
        <Box direction="row" gap="small" align="center">
          <Box>
            <Loading size="small" />
          </Box>
          <Text size="xxsmall">Syncing with Dropbox...</Text>
        </Box>
      )}
    </Box>
  )
}

UgcDropboxStatus.propTypes = {
  id: PropTypes.string.isRequired,
  dropbox_file_id: PropTypes.string,
}

export default UgcDropboxStatus
