import { map, compact } from 'lodash/fp';

export default (state, action) => {
  switch (action.type) {
    case 'ADD_TOTAL_LOADED_PLACEMENTS':
      return {
        ...state,
        totalLoadedPlacements: state.totalLoadedPlacements + action.payload
      };
    case 'SET_LOADED_UGC':
      var selections = compact(map('placement_id', action.payload));
      return { ...state, loadedUgc: action.payload, selectedUgc: selections };
    case 'SET_UGC_QUERY':
      return {
        ...state,
        lastFilename: action.payload.filename,
        lastRelated: action.payload.related_products,
        lastTags: action.payload.tags,
        skipCount: 0
      };
    case 'ADD_SELECTED_UGC':
      return { ...state, selectedUgc: [...state.selectedUgc, selections] };
    case 'SET_LAST_FILENAME':
      return { ...state, lastFilename: action.payload };
    case 'SET_LAST_RELATED':
      return { ...state, lastRelated: action.payload };
    case 'SET_LAST_TAGS':
      return { ...state, lastTags: action.payload };
    case 'SET_SKIP_COUNT':
      return { ...state, skipCount: action.payload };
    case 'SET_TOTAL_PLACEMENTS':
      return { ...state, totalPlacements: action.payload };
    case 'SET_PLACEMENTS_PAGE':
      return { ...state, placementsPage: action.payload };
    case 'NEXT_PAGE':
      return { ...state, skipCount: state.skipCount + state.pageSize };
    case 'PREV_PAGE':
      return { ...state, skipCount: state.skipCount - state.pageSize };
    default:
      return state;
  }
};

export const initialState = {
  lastFilename: null,
  lastRelated: null,
  lastTags: null,
  totalLoadedPlacements: 0,
  placementsPage: 1,
  loadedUgc: [],
  skipCount: 0,
  totalPlacements: 0,
  pageSize: 54,
  isPublished: true,
  selectedUgc: []
};
