function numberWithCommas(x) {
  const num = x ? x.toString() : "0"
  const frag = num.split(".")
  const integer = frag[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  const decimal = frag[1]
  return `${integer}${
    decimal ? `.${decimal.length === 2 ? decimal : `${decimal}0`}` : ""
  }`
}

export default numberWithCommas
