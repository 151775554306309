import React from "react"
import PropTypes from "prop-types"
import { useQuery } from "@apollo/react-hooks"
import { Box, Heading } from "grommet"
import { get } from "lodash/fp"

import { GET_UGC_COUNTS } from "../queries/ugc"
import numberWithCommas from "../lib/numberWithCommas"
import Loading from "./Loading"
import FormUploadAssets from "./FormUploadAssets"

const HeaderPendingAssets = ({ ugcState, queryVariables, ...rest }) => {
  const { loading, data } = useQuery(GET_UGC_COUNTS)
  const pending = get("pending.aggregate.count", data)
  const total = get("all.aggregate.count", data)

  return (
    <Box {...rest}>
      {loading || !data ? (
        <Loading />
      ) : (
        <Box direction="row-responsive" gap="small" fill="horizontal">
          <Box width="large">
            <FormUploadAssets
              ugcState={ugcState}
              queryVariables={queryVariables}
            />
          </Box>
          <Box>
            <Heading level={2} margin="none">
              {numberWithCommas(pending)} pending asset
              {pending === 1 ? "" : "s"}
            </Heading>
            <Heading level={4} margin="none">
              {numberWithCommas(total)} total asset
              {total === 1 ? "" : "s"}
            </Heading>
          </Box>
        </Box>
      )}
    </Box>
  )
}

HeaderPendingAssets.propTypes = {
  ugcState: PropTypes.string,
  queryVariables: PropTypes.object,
}

export default HeaderPendingAssets
