import React, { useEffect, useRef, useMemo, memo, useState } from "react"
import PropTypes from "prop-types"
import { Box, Button, Text, Drop } from "grommet"
import { Revert } from "grommet-icons"
import { get, find, map, isEqual } from "lodash/fp"

import InputText from "./InputText"
import extractGid from "../lib/extractGid"

const calculateFilename = ({
  productNames,
  attribution,
  createdAt,
  originalFilename,
}) => {
  const splits = originalFilename.split(".")
  const ext = splits[splits.length - 1]
  const names = productNames.map(x => (x || "").replace(/ /g, "")).join("x")
  return `${names}_${attribution}-${new Date(createdAt).getTime()}.${ext}`
}

const InputAutoFilename = ({
  relatedProducts,
  attribution,
  createdAt,
  onCompute,
  allProducts,
  originalFilename,
  currentFilename,
  ...rest
}) => {
  const [hovering, setHovering] = useState(false)
  const buttonRef = useRef(null)
  const buttonDisabled = isEqual(originalFilename, currentFilename)
  const productNames = useMemo(
    () =>
      map(
        x =>
          get(
            "node.title",
            find(z => isEqual(extractGid(z.node.id), x), allProducts)
          ),
        relatedProducts
      ),
    [relatedProducts, allProducts]
  )
  useEffect(() => {
    onCompute(
      calculateFilename({
        productNames,
        attribution,
        createdAt,
        originalFilename,
      })
    )
  }, [productNames, attribution])
  return (
    <Box direction="row" align="center" style={{ position: "relative" }}>
      <InputText {...rest} />
      <Button
        disabled={buttonDisabled}
        icon={<Revert size="18px" />}
        ref={buttonRef}
        size="small"
        secondary
        onMouseOver={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        onClick={() => {
          setHovering(false)
          onCompute(originalFilename)
        }}
      />
      {hovering && !buttonDisabled && (
        <Drop
          align={{ bottom: "top", right: "right" }}
          target={buttonRef.current}
        >
          <Box pad="xsmall">
            <Text size="xsmall" color="dark-4">
              Revert to orignal filename: <br />
            </Text>
            <Text size="xsmall" style={{ fontStyle: "italic" }}>
              {originalFilename}
            </Text>
          </Box>
        </Drop>
      )}
    </Box>
  )
}

InputAutoFilename.propTypes = {
  relatedProducts: PropTypes.array,
  attribution: PropTypes.string,
  createdAt: PropTypes.string,
  onCompute: PropTypes.func.isRequired,
  allProducts: PropTypes.array,
  originalFilename: PropTypes.string,
  currentFilename: PropTypes.string,
}

export default memo(InputAutoFilename)
