import qs from "query-string"
import { isEqual } from "lodash/fp"

const generateFilterQueryString = (params = {}) => {
  const { page } = qs.parse(window.location.search)
  const revised = qs.stringify(
    {
      page: params.page || page,
      tags: params.tags,
      galleries: params.galleries,
      related_products: params.related_products,
      filename: params.filename,
      attribution: params.attribution,
      createdAfter: params.createdAfter,
      createdBefore: params.createdBefore,
    },
    { arrayFormat: "comma", skipNull: true, skipEmptyString: true }
  )
  return `${window.location.pathname}${
    isEqual("", revised) ? "" : `?${revised}`
  }`
}

export default generateFilterQueryString
