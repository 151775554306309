import React, { memo } from "react"
import PropTypes from "prop-types"
import { Box, Button, Heading, Select } from "grommet"
import { List, AppsRounded } from "grommet-icons"
import { Formik, Field } from "formik"
import {
  isEqual,
  get,
  reduce,
  filter,
  map,
  uniq,
  isNil,
  omitBy,
} from "lodash/fp"

import InputTags from "./InputTags"
import InputProducts from "./InputProducts"
import InputDebouncedText from "./InputDebouncedText"
import InputDatePicker from "./InputDatePicker"
import FormLabel from "./FormLabel"
import { toArray, initFormDate } from "../lib/utils"

const UgcFilterControls = ({
  allTags,
  allColors,
  allGalleries,
  queryParams = {},
  ugcState,
  onDisplayChange,
  onStateChange,
  ugcLayout,
  stacked,
  title,
  onSubmit,
  ...rest
}) => {
  const tagSource = reduce(
    (mem, x) => {
      mem[x.id] = x.name
      return mem
    },
    {},
    allTags
  )
  const tagOptions = map(
    x => ({
      value: parseInt(x.id),
      label: x.name,
    }),
    filter(
      x =>
        (toArray(queryParams.tags) || [])
          .map(y => parseInt(y))
          .indexOf(parseInt(x.id)) < 0,
      allTags
    )
  )

  const galleriesSource = reduce(
    (mem, x) => {
      mem[parseInt(x.id)] = x.title
      return mem
    },
    {},
    allGalleries
  )

  const galleryOptions = map(
    x => ({
      value: parseInt(x.id),
      label: x.title,
    }),
    filter(
      x =>
        (toArray(queryParams.galleries) || [])
          .map(y => parseInt(y))
          .indexOf(parseInt(x.id)) < 0,
      allGalleries
    )
  )

  return (
    <Box {...rest}>
      {(title || onDisplayChange) && (
        <Box
          border={{ side: "bottom", size: "1px", color: "light-4" }}
          direction="row"
          justify="center"
          pad={{ vertical: "small" }}
        >
          {title && (
            <Box flex={{ grow: 1 }} justify="end">
              <Heading level={4} margin="none">
                Uploads
              </Heading>
            </Box>
          )}
          <Box gap="xsmall" direction="row">
            {onDisplayChange && (
              <>
                <Button
                  onClick={() => onDisplayChange("list")}
                  hoverIndicator={true}
                  icon={
                    <List
                      color={isEqual("list", ugcLayout) ? "black" : "dark-5"}
                    />
                  }
                />
                <Button
                  onClick={() => onDisplayChange("thumb")}
                  hoverIndicator={true}
                  icon={
                    <AppsRounded
                      color={isEqual("thumb", ugcLayout) ? "black" : "dark-5"}
                    />
                  }
                />
              </>
            )}
            {ugcState && onStateChange && (
              <Select
                value={ugcState}
                size="small"
                options={["Pending", "Processed", "All"]}
                onChange={e => {
                  onStateChange(e.option)
                }}
              />
            )}
          </Box>
        </Box>
      )}
      {(isEqual("Processed", ugcState) || isEqual("All", ugcState)) && (
        <Box
          border={{ side: "bottom", size: "1px", color: "light-4" }}
          pad={{ bottom: "small" }}
        >
          <Formik
            initialValues={{
              tags: toArray(get("tags", queryParams)) || [],
              galleries: toArray(get("galleries", queryParams)) || [],
              related_products:
                toArray(get("related_products", queryParams)) || [],
              filename: get("filename", queryParams) || "",
              attribution: get("attribution", queryParams) || "",
              createdBefore: initFormDate(get("createdBefore", queryParams)),
              createdAfter: initFormDate(get("createdAfter", queryParams)),
            }}
            onSubmit={(values, form) => {
              const parsedValues = omitBy(
                value => isNil(value) || isEqual("", value),
                values
              )
              onSubmit(parsedValues, form)
            }}
          >
            {({ handleSubmit, setFieldValue, values }) => {
              return (
                <Box direction={stacked ? "column" : "row"} gap="medium">
                  <Box
                    className="meta-filters"
                    gap="small"
                    flex
                    height={{ min: "auto" }}
                  >
                    <Box direction="row" wrap={true} align="center">
                      <FormLabel text="Tags" />
                      <InputTags
                        autocompleteOptions={tagOptions}
                        dataSource={tagSource}
                        name="tags"
                        onApprove={value => {
                          setFieldValue("tags", uniq([...values.tags, value]))
                          handleSubmit()
                        }}
                        onRemove={value => {
                          setFieldValue(
                            "tags",
                            filter(x => x !== value, values.tags)
                          )
                          handleSubmit()
                        }}
                      />
                    </Box>
                    <Box direction="row" wrap={true} align="center">
                      <FormLabel text="Products" />
                      <InputProducts
                        name="related_products"
                        allColors={allColors}
                        onApprove={value => {
                          setFieldValue(
                            "related_products",
                            uniq([...values.related_products, value])
                          )
                          handleSubmit()
                        }}
                        onRemove={value => {
                          setFieldValue(
                            "related_products",
                            filter(x => x !== value, values.related_products)
                          )
                          handleSubmit()
                        }}
                      />
                    </Box>
                    <Box direction="row" wrap={true} align="center">
                      <FormLabel text="Galleries" />
                      <InputTags
                        autocompleteOptions={galleryOptions}
                        dataSource={galleriesSource}
                        name="galleries"
                        onApprove={value => {
                          setFieldValue(
                            "galleries",
                            uniq([...values.galleries, value])
                          )
                          handleSubmit()
                        }}
                        onRemove={value => {
                          setFieldValue(
                            "galleries",
                            filter(x => x !== value, values.galleries)
                          )
                          handleSubmit()
                        }}
                      />
                    </Box>
                    <Box direction="row" wrap={true} align="center">
                      <FormLabel text="Filename" />
                      <Box>
                        <Field
                          component={InputDebouncedText}
                          setFieldValue={setFieldValue}
                          name="filename"
                          onChange={v => {
                            setFieldValue("filename", v)
                            handleSubmit()
                          }}
                          border={{ side: "all", color: "light-3" }}
                          plain={false}
                          style={{ padding: "0.2rem 0.4rem" }}
                        />
                      </Box>
                    </Box>
                    <Box direction="row" wrap={true} align="center">
                      <FormLabel text="Attribution" />
                      <Box>
                        <Field
                          component={InputDebouncedText}
                          setFieldValue={setFieldValue}
                          name="attribution"
                          onChange={v => {
                            setFieldValue("attribution", v)
                            handleSubmit()
                          }}
                          border={{ side: "all", color: "light-3" }}
                          plain={false}
                          style={{ padding: "0.2rem 0.4rem" }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    className="date-filters"
                    flex
                    pad={stacked ? "none" : "small"}
                    gap="small"
                    height={{ min: "auto" }}
                  >
                    <FormLabel text="Created after" />
                    <Box pad={{ horizontal: "small" }}>
                      <Field
                        component={InputDatePicker}
                        size="small"
                        onChange={v => {
                          setFieldValue("createdAfter", v)
                          handleSubmit()
                        }}
                        name="createdAfter"
                      />
                    </Box>
                    <FormLabel text="Created before" />
                    <Box pad={{ horizontal: "small" }}>
                      <Field
                        component={InputDatePicker}
                        size="small"
                        onChange={v => {
                          setFieldValue("createdBefore", v)
                          handleSubmit()
                        }}
                        name="createdBefore"
                      />
                    </Box>
                  </Box>
                </Box>
              )
            }}
          </Formik>
        </Box>
      )}
    </Box>
  )
}

UgcFilterControls.propTypes = {
  allTags: PropTypes.array,
  allColors: PropTypes.array,
  allProducts: PropTypes.array,
  allGalleries: PropTypes.array,
  queryParams: PropTypes.object,
  ugcState: PropTypes.string,
  onDisplayChange: PropTypes.func,
  onStateChange: PropTypes.func,
  ugcLayout: PropTypes.string,
  stacked: PropTypes.bool,
  title: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
}

export default memo(UgcFilterControls)
