import React, { useCallback, useState, memo } from "react"
import PropTypes from "prop-types"
import ReactCrop from "react-image-crop"
import { Box, Button } from "grommet"
import { get, map } from "lodash/fp"
import "react-image-crop/dist/ReactCrop.css"
import styled from "styled-components"
import Loading from "./Loading"

const StyledBox = styled(Box)`
  .ReactCrop__image {
    max-height: 80vh;
  }
`

const ImageCropper = ({ src, onCrop, onCancel, loading }) => {
  const [loadedImage, setLoadedImage] = useState(null)
  const [crop, setCrop] = useState({})
  const [cropParams, setCropParams] = useState({})
  const onImageLoaded = useCallback(image => {
    setLoadedImage(image)
  }, [])
  const onCropComplete = useCallback(
    crop => {
      const { width, height, x, y } = crop
      if (loadedImage) {
        const scaleX =
          (get("naturalWidth", loadedImage) || loadedImage.width) /
          loadedImage.width
        const scaleY =
          (get("naturalHeight", loadedImage) || loadedImage.height) /
          loadedImage.height

        const result = {
          rect: map(Math.round, [
            x * scaleX,
            y * scaleY,
            width * scaleX,
            height * scaleY,
          ]),
        }
        setCropParams(result)
      }
    },
    [loadedImage]
  )

  return (
    <StyledBox fill align="center">
      <ReactCrop
        src={src}
        disabled={!loadedImage || loading}
        crop={crop}
        onChange={setCrop}
        onComplete={onCropComplete}
        onImageLoaded={onImageLoaded}
      />
      {loadedImage ? (
        <Box gap="small" direction="row" pad="xsmall">
          <Button
            primary
            label="Duplicate & Crop"
            icon={loading ? <Loading /> : undefined}
            disabled={loading}
            onClick={() => onCrop(cropParams)}
          />
          <Button label="Cancel" onClick={() => onCancel()} />
        </Box>
      ) : (
        <Box>
          <Loading size="large" />
        </Box>
      )}
    </StyledBox>
  )
}

ImageCropper.propTypes = {
  onCrop: PropTypes.func.isRequired,
  src: PropTypes.string,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
}

export default memo(ImageCropper)
