import React from "react"
import PropTypes from "prop-types"
import { Formik, Form, Field } from "formik"
import { Box, Text, Button, DropButton } from "grommet"
import { MoreVertical, Trash, Duplicate } from "grommet-icons"
import { useMutation } from "@apollo/react-hooks"
import { formatDistanceToNow } from "date-fns"
import {
  isFunction,
  isNil,
  map,
  reduce,
  filter,
  reject,
  get,
  uniq,
  split,
  head,
} from "lodash/fp"

import { UPDATE_UGC, DELETE_UGC, UgcFragment } from "../queries/ugc"
import { UgcMetaSchema, UgcMetaCompletedSchema } from "../schemas/ugc"
import { setCropping } from "../state/actions/ui"
import InputText from "./InputText"
import InputTags from "./InputTags"
import InputTextArea from "./InputTextArea"
import InputProducts from "./InputProducts"
import InputAutoFilename from "./InputAutoFilename"
import FormLabel from "./FormLabel"
import ButtonConfirm from "./ButtonConfirm"
import Loading from "./Loading"
import UgcImagePreview from "./UgcImagePreview"
import UgcDropboxStatus from "./UgcDropboxStatus"
import ContributionMeta from "./ContributionMeta"
import extractGid from "../lib/extractGid"
import { immutableMove } from "../lib/utils"

const FormUgcMeta = ({
  id,
  filename = "",
  attribution = "",
  notes = "",
  createdAt,
  updatedAt,
  review,
  inserted_by,
  insertion_source,
  dropbox_file_id,
  tags = [],
  placements = [],
  processed,
  related_products = [],
  allTags = [],
  allProducts = [],
  allColors = [],
  allGalleries = [],
  onCancel,
  onSave,
  ...rest
}) => {
  const [deleteUgc] = useMutation(DELETE_UGC, {
    refetchQueries: ["UgcCounts", "ListUgc"],
    optimisticResponse: {
      __typename: "Mutation",
      deleteUgc: {
        ugc: { id },
      },
    },
    update: (cache, { data: { deleteUgc } }) => {
      const ugc = cache.readFragment({
        id: `Ugc:${deleteUgc.ugc.id}`,
        fragment: UgcFragment,
      })
      if (ugc) {
        cache.writeFragment({
          id: `Ugc:${deleteUgc.ugc.id}`,
          fragment: UgcFragment,
          data: {
            _deleted: true,
          },
        })
      }
    },
  })
  const [updateUgc] = useMutation(UPDATE_UGC)

  const handleDelete = () => {
    deleteUgc({ variables: { input: { where: { id } } } })
  }

  const handleDuplicateCrop = values => {
    setCropping({
      ugc: {
        id,
        filename,
        dropbox_file_id,
        placements,
        tags,
        related_products,
        processed,
        ...rest,
      },
      values,
    })
  }

  return (
    <Formik
      initialValues={{
        filename,
        attribution: isNil(attribution)
          ? isNil(review)
            ? ""
            : `${review.first_name} ${head(split("", review.last_name))}`
          : attribution,
        notes: notes || "",
        tags: map(x => parseInt(get("id", x)), tags),
        placements,
        galleries: uniq(
          map(x => parseInt(get("ugc_gallery.id", x)), placements)
        ),
        related_products,
      }}
      validationSchema={UgcMetaSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true)
        const updated = await updateUgc({
          variables: {
            input: {
              where: { id },
              data: {
                ...values,
                placements: map(x => parseInt(get("id", x)), values.placements),
              },
            },
          },
          refetchQueries: values.processed ? ["ListUgc", "UgcCounts"] : [],
        })
        setSubmitting(false)
        if (onSave && isFunction(onSave)) {
          onSave(updated)
        }
      }}
    >
      {({ isSubmitting, isValid, handleSubmit, setFieldValue, values }) => {
        const isValidForCompletion = UgcMetaCompletedSchema.isValidSync(values)

        const tagSource = reduce(
          (mem, x) => {
            mem[x.id] = x.name
            return mem
          },
          {},
          allTags
        )

        const tagOptions = map(
          x => ({
            value: parseInt(x.id),
            label: x.name,
          }),
          filter(
            x =>
              map(z => parseInt(get("id", z)), values.tags).indexOf(
                parseInt(x.id)
              ) < 0,
            allTags
          )
        )

        const productsSource = reduce(
          (mem, x) => {
            mem[
              extractGid(x.node.id)
            ] = `${x.node.title} (${x.node.productType})`
            return mem
          },
          {},
          allProducts
        )

        const productOptions = map(
          x => ({
            value: extractGid(x.node.id),
            label: `${x.node.title} (${x.node.productType})`,
          }),
          filter(x => {
            return values.related_products.indexOf(extractGid(x.node.id)) < 0
          }, allProducts)
        )

        const galleriesSource = reduce(
          (mem, x) => {
            mem[parseInt(x.id)] = x.title
            return mem
          },
          {},
          allGalleries
        )

        const galleryOptions = map(
          x => ({
            value: parseInt(x.id),
            label: x.title,
          }),
          reject(
            x => x.id === "510",
            filter(x => {
              return values.galleries.indexOf(x.id) < 0
            }, allGalleries)
          )
        )

        const handleProductReorder = (dragIndex, hoverIndex) => {
          setFieldValue(
            "related_products",
            immutableMove(values.related_products, dragIndex, hoverIndex)
          )
        }

        return (
          <Form style={{ width: "100%" }}>
            <Box
              direction="row-responsive"
              className="form-ugc-meta"
              gap="small"
            >
              <Box width="medium" flex={false}>
                <UgcImagePreview {...rest} id={id} filename={filename} />
              </Box>
              <Box
                flex={{ grow: 1, shrink: 1 }}
                className="meta"
                justify="between"
              >
                <Box>
                  <Box>
                    <InputAutoFilename
                      allProducts={allProducts}
                      relatedProducts={values.related_products}
                      attribution={values.attribution}
                      originalFilename={filename}
                      currentFilename={values.filename}
                      createdAt={createdAt}
                      onCompute={x => setFieldValue("filename", x)}
                      fontWeight={600}
                      name="filename"
                      placeholder="filename"
                      size="medium"
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                    />
                    <Box pad={{ horizontal: "small" }}>
                      <ContributionMeta
                        inserted_by={
                          review
                            ? {
                                id: review.id,
                                username: `${review.first_name} ${review.last_name}`,
                              }
                            : inserted_by
                        }
                        insertion_source={insertion_source}
                        createdAt={createdAt}
                      />
                    </Box>
                  </Box>
                  <Box className="meta-inner" direction="row-responsive">
                    <Box flex={{ grow: 1, shrink: 1 }}>
                      <InputText
                        name="attribution"
                        placeholder="@attribution (required)"
                      />
                      <Box>
                        <FormLabel text="Tags" />
                        <InputTags
                          autocompleteOptions={tagOptions}
                          dataSource={tagSource}
                          name="tags"
                          onApprove={value => {
                            setFieldValue("tags", uniq([...values.tags, value]))
                          }}
                          onRemove={value => {
                            setFieldValue(
                              "tags",
                              filter(x => x !== value, values.tags)
                            )
                          }}
                        />
                      </Box>
                      <Box>
                        <FormLabel
                          text={
                            <span>
                              Products{" "}
                              <Text size="xxsmall" color="dark-4" weight={400}>
                                at least 1 required
                              </Text>
                            </span>
                          }
                        />
                        <InputProducts
                          autocompleteOptions={productOptions}
                          dataSource={productsSource}
                          useProductColor={true}
                          allColors={allColors}
                          name="related_products"
                          onReorder={handleProductReorder}
                          setFieldValue={setFieldValue}
                          onApprove={value => {
                            setFieldValue(
                              "related_products",
                              uniq([...values.related_products, value])
                            )
                          }}
                          onRemove={value => {
                            setFieldValue(
                              "related_products",
                              filter(x => x !== value, values.related_products)
                            )
                          }}
                        />
                      </Box>
                    </Box>
                    <Box flex={{ grow: 0, shrink: 0 }} basis="1/2">
                      <Box>
                        <FormLabel text="Notes" />
                        <Box pad="small">
                          <Field
                            component={InputTextArea}
                            size="xsmall"
                            name="notes"
                          />
                        </Box>
                      </Box>
                      <Box>
                        <FormLabel text="Gallery Placements" />
                        <InputTags
                          autocompleteOptions={galleryOptions}
                          dataSource={galleriesSource}
                          name="galleries"
                          round="medium"
                          onApprove={value => {
                            setFieldValue(
                              "galleries",
                              uniq([...values.galleries, value])
                            )
                          }}
                          onRemove={value => {
                            setFieldValue(
                              "placements",
                              reject(
                                x =>
                                  parseInt(get("ugc_gallery.id", x)) === value,
                                values.placements
                              )
                            )
                            setFieldValue(
                              "galleries",
                              filter(x => x !== value, values.galleries)
                            )
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  pad="small"
                  direction="row"
                  justify="between"
                  gap="small"
                  align="center"
                >
                  <Box align="center">
                    <UgcDropboxStatus
                      id={id}
                      dropbox_file_id={dropbox_file_id}
                    />
                  </Box>
                  <Box direction="row" justify="end" align="center" gap="small">
                    {isSubmitting ? (
                      <Loading />
                    ) : (
                      <Text size="xxsmall">
                        Last updated{" "}
                        {formatDistanceToNow(new Date(updatedAt), {
                          addSuffix: true,
                        })}
                      </Text>
                    )}

                    {onCancel && (
                      <Button
                        onClick={onCancel}
                        secondary
                        label="Cancel"
                        size="small"
                      />
                    )}

                    <Button
                      onClick={handleSubmit}
                      secondary={!processed}
                      primary={processed}
                      label="Save"
                      size="small"
                      disabled={
                        processed
                          ? !isValid || !isValidForCompletion || isSubmitting
                          : !isValid || isSubmitting
                      }
                    />

                    {!processed && (
                      <Button
                        onClick={() => {
                          setFieldValue("processed", true)
                          handleSubmit()
                        }}
                        primary
                        label="Mark Complete"
                        size="small"
                        disabled={
                          !isValid || !isValidForCompletion || isSubmitting
                        }
                      />
                    )}
                    <DropButton
                      icon={<MoreVertical />}
                      style={{ padding: 0 }}
                      dropAlign={{
                        top: "bottom",
                        right: "right",
                      }}
                      dropContent={
                        <Box pad="small" gap="small" align="start">
                          <Button
                            label="Duplicate & Crop"
                            gap="small"
                            justify="between"
                            icon={<Duplicate />}
                            onClick={() => handleDuplicateCrop(values)}
                            size="small"
                            plain
                          />
                          <ButtonConfirm
                            label="Delete UGC"
                            color="status-critical"
                            gap="small"
                            icon={<Trash color="status-critical" />}
                            onClick={handleDelete}
                            confirmation="Are you sure you want to delete this UGC? This cannot be undone."
                            confirmTitle="Delete UGC"
                            size="small"
                            plain
                          />
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

FormUgcMeta.propTypes = {
  id: PropTypes.string,
  filename: PropTypes.string,
  attribution: PropTypes.string,
  notes: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  review: PropTypes.object,
  inserted_by: PropTypes.object,
  insertion_source: PropTypes.string,
  dropbox_file_id: PropTypes.string,
  tags: PropTypes.array,
  related_products: PropTypes.array,
  allTags: PropTypes.array,
  allProducts: PropTypes.array,
  placements: PropTypes.array,
  processed: PropTypes.bool,
  allColors: PropTypes.array,
  allGalleries: PropTypes.array,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
}

export default FormUgcMeta
