import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button, Text, Box } from "grommet"
import Modal from "./Modal"

const ButtonConfirm = ({
  onClick,
  confirmTitle = "Are you sure?",
  confirmation = "Are you sure?",
  confirmLabel = "Yes",
  cancelLabel = "Cancel",
  ...rest
}) => {
  const [asking, setAsking] = useState(false)
  const handleClick = () => {
    setAsking(true)
  }
  return (
    <>
      {asking && (
        <Modal title={confirmTitle} onClose={() => setAsking(false)}>
          <Box pad="small">
            <Text>{confirmation}</Text>
            <Box
              direction="row"
              gap="small"
              justify="end"
              margin={{ top: "medium" }}
            >
              <Button
                primary
                label={confirmLabel}
                onClick={() => {
                  onClick()
                  setAsking(false)
                }}
              />
              <Button label={cancelLabel} onClick={() => setAsking(false)} />
            </Box>
          </Box>
        </Modal>
      )}
      <Button {...rest} onClick={handleClick} />
    </>
  )
}

ButtonConfirm.propTypes = {
  onClick: PropTypes.func,
  confirmTitle: PropTypes.string,
  confirmation: PropTypes.string,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
}

export default ButtonConfirm
