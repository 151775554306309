import gql from "graphql-tag"

const UGC_GALLERY_PLACEMENTS = gql`
  query UgcGalleryPlacementsLookup($start: Int, $limit: Int, $galleryId: ID!) {
    ugcGalleryPlacementsLookup(
      start: $start
      limit: $limit
      galleryId: $galleryId
    ) {
      id
      rect
      index
      layout
      ugc {
        id
        filename
        attribution
        review {
          id
        }
        file {
          url
          id
        }
        related_products
      }
    }
    ugcGalleryPlacementsConnection(where: { ugc_gallery: $galleryId }) {
      aggregate {
        count
      }
    }
  }
`

export default UGC_GALLERY_PLACEMENTS
