import React from "react"
import PropTypes from "prop-types"
import qs from "query-string"
import { Grommet } from "grommet"
import { Router } from "@reach/router"
import Layout from "../components/Layout"
import Manage from "../components/ViewManage"
import GalleryEditor from "../components/ViewGalleryEditor"
import Login from "../components/ViewLogin"
import PrivateRoute from "../components/PrivateRoute"
import { ShopifyDataProvider } from "../components/useShopifyData"
import { GalleryAdminProvider } from "../components/UgcGallery/UgcGalleryEditor/useGalleryAdmin"
import { DisplacedUgcProvider } from "../components/UgcGallery/DisplacedUgc/useDisplacedUgc"
import { ShopifyProductsProvider } from "../components/UgcGallery/UgcGalleryEditor/useShopifyProducts"
import theme from "../components/theme"

const App = ({ location }) => {
  const queryParams = qs.parse(location.search, {
    arrayFormat: "comma",
  })
  return (
    <Grommet theme={theme} full>
      <GalleryAdminProvider>
        <ShopifyProductsProvider>
          <DisplacedUgcProvider>
            <ShopifyDataProvider>
              <Layout>
                <Router basepath="/" className="router">
                  <PrivateRoute
                    path="/"
                    ugcState="Pending"
                    queryParams={queryParams}
                    component={Manage}
                  />
                  <PrivateRoute
                    path="/pending"
                    ugcState="Pending"
                    queryParams={queryParams}
                    component={Manage}
                  />
                  <PrivateRoute
                    path="/processed"
                    ugcState="Processed"
                    queryParams={queryParams}
                    component={Manage}
                  />
                  <PrivateRoute
                    path="/all"
                    ugcState="All"
                    queryParams={queryParams}
                    component={Manage}
                  />
                  <PrivateRoute
                    path="/gallery-editor"
                    queryParams={queryParams}
                    component={GalleryEditor}
                  />
                  <PrivateRoute
                    path="/gallery-editor/:galleryId"
                    queryParams={queryParams}
                    component={GalleryEditor}
                  />
                  <Login path="/login" />
                </Router>
              </Layout>
            </ShopifyDataProvider>
          </DisplacedUgcProvider>
        </ShopifyProductsProvider>
      </GalleryAdminProvider>
    </Grommet>
  )
}

App.propTypes = {
  location: PropTypes.object,
}

export default App
