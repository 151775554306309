import React, { useRef } from "react"
import PropTypes from "prop-types"
import { useDrag, useDrop } from "react-dnd"
import { Box, Text } from "grommet"
import { Checkmark, Close } from "grommet-icons"
import Loading from "./Loading"

const Tag = ({
  label,
  id,
  background = "light-2",
  round,
  onApprove,
  onRemove,
  onReorder,
  index,
  pad = "small",
  margin = { bottom: "xsmall" },
  textSize = "xsmall",
  border,
  loading,
}) => {
  const ref = useRef(null)
  const [, drop] = useDrop({
    accept: "tag",
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      onReorder(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag] = useDrag({
    item: { type: "tag", id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0 : 1
  if (onReorder) {
    drag(drop(ref))
  }

  return (
    <Box
      margin={margin}
      pad={pad}
      background={background}
      direction="row"
      justify="between"
      align="center"
      gap="small"
      round={round || "2px"}
      ref={ref}
      style={{
        opacity: onReorder ? opacity : onApprove ? 0.6 : 1,
        cursor: onReorder ? "move" : "default",
      }}
      border={
        border
          ? border
          : onApprove
          ? { side: "all", style: "dashed", color: "white" }
          : null
      }
    >
      {loading ? <Loading /> : <Text size={textSize}>{label}</Text>}
      {(onRemove || onApprove) && (
        <Box gap="xsmall" direction="row">
          {onRemove && (
            <Close
              onClick={() => onRemove(id, label)}
              size="16px"
              cursor="pointer"
            />
          )}
          {onApprove && (
            <Checkmark
              onClick={() => onApprove(id, label)}
              size="1rem"
              cursor="pointer"
            />
          )}
        </Box>
      )}
    </Box>
  )
}

Tag.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  round: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onRemove: PropTypes.func,
  onApprove: PropTypes.func,
  background: PropTypes.string,
  onReorder: PropTypes.func,
  index: PropTypes.number,
  pad: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  textSize: PropTypes.string,
  border: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  loading: PropTypes.bool,
}

export default Tag
