import React from "react"
import PropTypes from "prop-types"
import { Box, Button, Heading, Text, Paragraph } from "grommet"
import { StatusGood } from "grommet-icons"
import { get, uniq, map } from "lodash/fp"
import { formatDistanceToNow } from "date-fns"

import toIdMap from "../lib/toIdMap"
import UgcImagePreview from "./UgcImagePreview"
import FormLabel from "./FormLabel"
import TagList from "./TagList"
import ContributionMeta from "./ContributionMeta"
import extractGid from "../lib/extractGid"

const UgcMeta = ({
  id,
  filename,
  createdAt,
  updatedAt,
  inserted_by,
  insertion_source,
  tags,
  related_products,
  attribution,
  notes,
  review,
  placements,
  allTags,
  allColors,
  allProducts,
  allGalleries,
  onEdit,
  processed,
  ...rest
}) => {
  const galleries = uniq(
    map(x => parseInt(get("ugc_gallery.id", x)), placements)
  )
  return (
    <Box direction="row-responsive" className="form-ugc-meta" gap="small">
      <Box
        width="medium"
        height="medium"
        flex={false}
        className="image-container"
      >
        <UgcImagePreview {...rest} id={id} filename={filename} />
      </Box>
      <Box flex={{ grow: 1, shrink: 1 }} justify="between" className="meta">
        <Box>
          <Box pad="small">
            <Box direction="row" justify="between" align="center">
              <Heading truncate={true} level={4} margin="none">
                {filename}
              </Heading>
              {processed && <StatusGood color="status-ok" />}
            </Box>
            <ContributionMeta
              createdAt={createdAt}
              insertion_source={insertion_source}
              inserted_by={
                review
                  ? {
                      id: review.id,
                      username: `${review.first_name} ${review.last_name}`,
                    }
                  : inserted_by
              }
            />
          </Box>
          <Box className="meta-inner" direction="row-responsive">
            <Box flex={true} basis={1 / 2}>
              <Box pad="small">
                <Text>{attribution}</Text>
              </Box>
              <Box>
                <FormLabel text="Tags" />
                <TagList
                  tags={tags}
                  dataSource={toIdMap(allTags, "name")}
                  allColors={allColors}
                />
              </Box>
              <Box>
                <FormLabel text="Products" />
                <TagList
                  tags={related_products}
                  dataSource={toIdMap(
                    allProducts,
                    x =>
                      `${get("node.title", x)} (${get("node.productType", x)})`,
                    x => extractGid(get("node.id", x))
                  )}
                  allColors={allColors}
                />
              </Box>
            </Box>
            <Box flex={true} basis={1 / 2} overflow="auto">
              <Box>
                <FormLabel text="Notes" />
                <Box pad={{ horizontal: "small" }}>
                  <Paragraph size="small">{notes}</Paragraph>
                </Box>
              </Box>
              <Box>
                <FormLabel text="Gallery Placements" />
                <TagList
                  tags={galleries}
                  dataSource={toIdMap(allGalleries, "title")}
                  allColors={allColors}
                  round="medium"
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          pad="small"
          direction="row"
          justify="end"
          gap="small"
          align="center"
        >
          <Text size="xxsmall">
            Last updated{" "}
            {formatDistanceToNow(new Date(updatedAt), {
              addSuffix: true,
            })}
          </Text>
          <Button onClick={onEdit} secondary label="Edit" size="small" />
        </Box>
      </Box>
    </Box>
  )
}

UgcMeta.propTypes = {
  id: PropTypes.string.isRequired,
  filename: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  inserted_by: PropTypes.object,
  insertion_source: PropTypes.string,
  tags: PropTypes.array,
  related_products: PropTypes.array,
  attribution: PropTypes.string,
  notes: PropTypes.string,
  review: PropTypes.object,
  placements: PropTypes.array,
  allTags: PropTypes.array,
  allColors: PropTypes.array,
  allProducts: PropTypes.array,
  allGalleries: PropTypes.array,
  onEdit: PropTypes.func.isRequired,
  processed: PropTypes.bool,
}

export default UgcMeta
