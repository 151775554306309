import React from "react"
import PropTypes from "prop-types"
import { TextArea } from "grommet"

const InputTextArea = ({ field, ...rest }) => {
  return <TextArea name={field.name} {...field} {...rest} />
}

InputTextArea.propTypes = {
  field: PropTypes.object,
}

export default InputTextArea
