import React, { useState, useMemo, useCallback, useRef } from "react"
import PropTypes from "prop-types"
import { Box, TextInput, Drop } from "grommet"
import { Field } from "formik"
import Fuse from "fuse.js"
import { get, map, take } from "lodash/fp"

import colorByProductId from "../selectors/colorByProductId"
import LabelForm from "./LabelForm"
import ButtonAddCancel from "./ButtonAddCancel"
import Tag from "./Tag"

const searchOptions = {
  keys: ["label"],
  threshold: 0.3,
}

const InputTags = ({
  name,
  label,
  onRemove,
  onApprove,
  onReorder,
  suggestions = [],
  autocompleteOptions,
  dataSource,
  allColors = [],
  useProductColor = false,
  round = "0",
}) => {
  const [isAdding, setIsAdding] = useState(false)
  const addButton = useRef()

  const [listSearch, setListSearch] = useState("")
  const list = useMemo(
    () => new Fuse(autocompleteOptions || [], searchOptions),
    [autocompleteOptions]
  )
  const searchMatches = useMemo(
    () =>
      listSearch === ""
        ? autocompleteOptions
        : map(x => get("item", x), take(8, list.search(listSearch))),
    [listSearch, list]
  )
  const getBackground = useCallback(
    x =>
      useProductColor
        ? get("hex_value", colorByProductId(allColors, x))
        : undefined,
    [allColors, useProductColor]
  )

  return (
    <Box>
      <LabelForm label={label} />
      <Box direction="row" wrap={true} ref={addButton}>
        <Field name={name}>
          {({ field }) => {
            return (
              <Box
                direction={onReorder ? "column" : "row"}
                wrap={true}
                gap="xsmall"
                pad={{ vertical: "xsmall", horizontal: "small" }}
              >
                {field.value.map((x, i) => (
                  <Tag
                    key={x}
                    id={x}
                    label={dataSource[x]}
                    onRemove={onRemove}
                    background={getBackground(x)}
                    round={round}
                    onReorder={onReorder}
                    index={i}
                  />
                ))}
                {suggestions.map(x => (
                  <Tag
                    key={x}
                    id={x}
                    label={dataSource[x]}
                    onRemove={onRemove}
                    onApprove={onApprove}
                    background={getBackground(x)}
                    round={round}
                  />
                ))}

                <ButtonAddCancel
                  isAdding={isAdding}
                  onClick={() => setIsAdding(!isAdding)}
                />
              </Box>
            )
          }}
        </Field>
      </Box>
      {isAdding && (
        <Drop
          target={addButton.current}
          onClickOutside={() => setIsAdding(false)}
          onEsc={() => setIsAdding(false)}
          elevation="none"
          align={{ top: "bottom" }}
        >
          <Box pad={{ horizontal: "small", vertical: "xsmall" }}>
            <TextInput
              autoFocus={true}
              suggestions={searchMatches}
              value={listSearch}
              onChange={e => setListSearch(e.target.value)}
              onSelect={e => {
                onApprove(e.suggestion.value, dataSource[e.suggestion.value])
                setListSearch("")
              }}
            />
          </Box>
        </Drop>
      )}
    </Box>
  )
}

InputTags.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onRemove: PropTypes.func,
  onApprove: PropTypes.func,
  onReorder: PropTypes.func,
  suggestions: PropTypes.array,
  autocompleteOptions: PropTypes.array,
  dataSource: PropTypes.object.isRequired,
  allColors: PropTypes.array,
  useProductColor: PropTypes.bool,
  round: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}

export default InputTags
