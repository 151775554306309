import React, { memo } from "react"
import PropTypes from "prop-types"
import { Box } from "grommet"

import UgcGalleryPreviewGroupLayoutHero from "../UgcGalleryPreviewGroupLayoutHero"
import UgcGalleryPreviewGroupLayoutSalon from "../UgcGalleryPreviewGroupLayoutSalon"
import UgcGalleryPreviewGroupLayoutUniform from "../UgcGalleryPreviewGroupLayoutUniform"

const UgcGalleryPreviewGroup = props => {
  const { items, galleryLayout, index } = props
  const usesHeroes = galleryLayout === "alternating_hero"
  const isHeroLayout = usesHeroes && index % 3 === 0
  const heroIndex = isHeroLayout ? 0 : false
  const heroItem = items[heroIndex]
  const alternateHero = index % 6 !== 0

  return (
    <Box
      direction="row"
      wrap={true}
      className="ugc-group-container"
      height={isHeroLayout ? "auto" : "99%"}
      fill="horizontal"
      gap="xxsmall"
      style={{ position: "relative" }}
    >
      {isHeroLayout && heroItem && (
        <UgcGalleryPreviewGroupLayoutHero
          {...props}
          heroIndex={heroIndex}
          heroItem={heroItem}
          alternateHero={alternateHero}
        />
      )}
      {usesHeroes && !isHeroLayout && (
        <UgcGalleryPreviewGroupLayoutUniform {...props} />
      )}
      {galleryLayout === "uniform" && (
        <UgcGalleryPreviewGroupLayoutUniform {...props} />
      )}
      {galleryLayout === "salon" && (
        <UgcGalleryPreviewGroupLayoutSalon {...props} />
      )}
    </Box>
  )
}

UgcGalleryPreviewGroup.propTypes = {
  items: PropTypes.array,
  galleryLayout: PropTypes.string,
  index: PropTypes.number,
}

export default memo(UgcGalleryPreviewGroup)
