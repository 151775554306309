import React from "react"
import PropTypes from "prop-types"
import ky from "ky"
import { FormField, Box, Button } from "grommet"
import { Formik, Form } from "formik"
import { get } from "lodash/fp"

import { LoginSchema } from "../schemas/auth"
import InputText from "./InputText"

const FormLogin = ({ onSuccess }) => {
  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      validateOnMount={true}
      validateOnBlur={true}
      validationSchema={LoginSchema}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        setSubmitting(true)
        try {
          const loginResponse = await ky
            .post(process.env.GATSBY_BACKDROP_ADMIN_AUTH_URL, { json: values })
            .json()
          onSuccess(get("data", loginResponse))
        } catch (e) {
          setFieldError("email", "Email or password is incorrect")
          setSubmitting(false)
        }
      }}
    >
      {({ isSubmitting, isValid, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <FormField name="email" htmlfor="email" label="Email">
            <InputText name="email" type="email" />
          </FormField>
          <FormField name="email" htmlfor="password" label="Password">
            <InputText name="password" type="password" />
          </FormField>
          <Box direction="row" gap="medium">
            <Button
              type="submit"
              primary
              label="Submit"
              disabled={isSubmitting || !isValid}
              onClick={handleSubmit}
            />
          </Box>
        </Form>
      )}
    </Formik>
  )
}

FormLogin.propTypes = {
  onSuccess: PropTypes.func.isRequired,
}

export default FormLogin
