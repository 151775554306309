import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client"

const shopifyClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          products: {
            keyArgs: false,
            merge(existing = { edges: [] }, incoming, settings) {
              if (settings.variables?.query) {
                return incoming
              }
              return {
                edges: [...existing?.edges, ...incoming?.edges],
                pageInfo: incoming?.pageInfo,
              }
            },
          },
        },
      },
    },
  }),
  link: new HttpLink({
    uri: process.env.GATSBY_SHOPIFY_API_URL,
    headers: {
      "X-Shopify-Storefront-Access-Token":
        process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
    },
  }),
})

export default shopifyClient
