import React from "react"
import PropTypes from "prop-types"
import { Box, Text } from "grommet"
import { MailOption, UploadOption, Tape, Star, Instagram } from "grommet-icons"
import { get } from "lodash/fp"
import { format } from "date-fns"

const Icon = ({ source }) => {
  const size = "14px"
  switch (source) {
    case "email":
      return <MailOption size={size} />
    case "upload":
      return <UploadOption size={size} />
    case "review":
      return <Star size={size} />
    case "instagram":
      return <Instagram size={size} />
    default:
      return <Tape size={size} />
  }
}

Icon.propTypes = {
  source: PropTypes.string,
}

const ContributionMeta = ({ createdAt, insertion_source, inserted_by }) => {
  return (
    <Box direction="row" wrap={true} gap="xsmall" align="center">
      <Icon source={insertion_source} />
      {createdAt && (
        <Text color="dark-5" size="xsmall">
          {format(new Date(createdAt), "M/d/yyyy h:mm a")}
        </Text>
      )}
      {inserted_by && (
        <Text color="dark-5" size="xsmall">
          {get("username", inserted_by)}
        </Text>
      )}
    </Box>
  )
}

ContributionMeta.propTypes = {
  createdAt: PropTypes.string,
  insertion_source: PropTypes.string,
  inserted_by: PropTypes.string,
}

export default ContributionMeta
