import React, { useState, memo, Fragment } from "react"
import PropTypes from "prop-types"
import { Box, Button } from "grommet"
import Imgix from "react-imgix"
import styled from "styled-components"
import { get, filter, map, compact, take } from "lodash/fp"
import { useDrag, useDrop } from "react-dnd"
import { Image } from "grommet-icons"

import ProductTag, { ProductTagFetched } from "../ProductTag"
import { getClientForUrl, getKeyFromUrl } from "../../../lib/imgixClient"
import { useShopifyProducts } from "../UgcGalleryEditor/useShopifyProducts"
import { isPaintType, isApparelType } from "../../../lib/productTypes"

const ImageContainer = styled(Box)`
  position: relative;
  > img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    flex: 1 1;
  }
`

const ProductTags = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
`

const HoverControls = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  ${p => p.draggable && "cursor: grab;"}
`

const droppableLayouts = ["alternating_hero", "salon"]

const UgcGalleryPreviewItem = ({
  id,
  index,
  ugc = {},
  height,
  large,
  inLargeGroup,
  onAction,
  replacing,
  rect = [],
  basis = "1/3",
  tagSize = "medium",
}) => {
  const file = get("file", ugc)
  const [isHovering, setHovering] = useState(false)

  const {
    state: { productMap, galleryLayout },
  } = useShopifyProducts()

  const isDroppable = droppableLayouts.indexOf(galleryLayout) > -1

  const [{ isOver }, drop] = useDrop({
    accept: ["UgcPlacement", "Ugc"],
    drop: item => {
      onAction("drop", { id, item })
    },
    canDrop: () => isDroppable,
    collect: mon => ({
      isOver: !!mon.isOver() && isDroppable,
    }),
  })
  const [, drag] = useDrag({
    item: { type: "UgcPlacement", id, ugc, rect },
    canDrag: () => isDroppable,
  })

  const client = file ? getClientForUrl(file.url || "") : null
  const imageKey = file ? getKeyFromUrl(file.url || "") : null
  const hasCrop = Array.isArray(rect) && rect.length > 0
  const square = !hasCrop && !inLargeGroup && !large
  const baseOpts = {
    rect: (rect || []).join(","),
    fit: "crop",
    auto: "compress",
    q: "90",
  }

  const imgOpts = square
    ? { ...baseOpts, ar: "1:1", crop: "entropy" }
    : baseOpts

  const src = client ? client.buildURL(imageKey) : null

  const relatedProducts = compact(
    map(x => productMap[x], get("related_products", ugc) || [])
  )
  const unfetchedProducts = filter(
    x => !productMap[x],
    get("related_products", ugc)
  )

  const getFeaturedTaggedProducts = products => {
    return take(
      2,
      filter(x => {
        return (
          isPaintType(get("productType", x)) ||
          isApparelType(get("productType", x))
        )
      }, products)
    )
  }

  const isReplacing = replacing === id

  return (
    <Box
      height={height}
      flex={{ grow: 1, shrink: 1 }}
      basis={basis}
      overflow="hidden"
      align="center"
      ref={drop}
      onMouseOver={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      elevation={isOver ? "large" : "none"}
      background="white"
      border={isOver ? { color: "yellow", size: "medium", side: "all" } : false}
    >
      <ImageContainer ref={drag} fill>
        {src ? (
          <Imgix
            src={src}
            style={{
              objectFit: "cover",
              opacity: isOver ? 0.5 : 1,
            }}
            imgixParams={imgOpts}
            sizes={large ? "40vw" : "30vw"}
          />
        ) : (
          <Box background="light-3" fill justify="center" align="center">
            <Image size="xlarge" />
          </Box>
        )}
        <ProductTags
          fill={large ? true : false}
          margin="small"
          justify={large ? "center" : "end"}
          gap="xxsmall"
        >
          {getFeaturedTaggedProducts(relatedProducts || []).map(x => (
            <ProductTag
              key={`${id}-product-${x.id}`}
              id={x}
              large={large}
              data={x}
              tagSize={tagSize}
            />
          ))}
          {(unfetchedProducts || []).map(x => (
            <ProductTagFetched
              key={`${id}-product-${x}`}
              id={x}
              large={large}
              tagSize={tagSize}
            />
          ))}
        </ProductTags>
        {(isHovering || isReplacing) && (
          <HoverControls
            fill
            align="center"
            justify="center"
            background={
              isReplacing
                ? "rgba(249, 244, 145, 0.5)"
                : "rgba(256, 256, 256, 0.6)"
            }
            gap="small"
            draggable={isDroppable}
          >
            {replacing ? (
              <Fragment>
                {isReplacing ? (
                  <Button
                    label="cancel"
                    onClick={() => {
                      onAction("cancelReplace", { destinationPlacementId: id })
                    }}
                  />
                ) : (
                  <Button
                    label="swap"
                    color="status-warning"
                    onClick={() =>
                      onAction("swap", { destinationPlacementId: id })
                    }
                  />
                )}
              </Fragment>
            ) : (
              <Box gap="xsmall">
                {src && isDroppable && (
                  <Button
                    label="crop"
                    primary
                    onClick={() => {
                      onAction("edit", { id, file })
                      setHovering(false)
                    }}
                  />
                )}
                {src && (
                  <Button
                    label="remove"
                    onClick={() => {
                      onAction("remove", { id })
                      setHovering(false)
                    }}
                  />
                )}
                {isDroppable && (
                  <Button
                    label="replace/swap"
                    onClick={() => {
                      onAction("replace", { id, index })
                    }}
                  />
                )}
              </Box>
            )}
          </HoverControls>
        )}
      </ImageContainer>
    </Box>
  )
}

UgcGalleryPreviewItem.propTypes = {
  id: PropTypes.string,
  index: PropTypes.number,
  ugc: PropTypes.object,
  height: PropTypes.string,
  large: PropTypes.object,
  inLargeGroup: PropTypes.bool,
  onAction: PropTypes.func.isRequired,
  replacing: PropTypes.bool,
  rect: PropTypes.array,
  basis: PropTypes.string,
  tagSize: PropTypes.string,
}

export default memo(UgcGalleryPreviewItem)
