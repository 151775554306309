import React, { memo } from "react"
import PropTypes from "prop-types"
import { Box } from "grommet"
import { get, map } from "lodash/fp"

import Spinner from "../../Loading"
import UgcListItem from "../UgcListItem"

const UgcList = ({
  loading,
  items,
  replacing,
  onAction,
  selected,
  removed,
}) => {
  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <Box gap="xsmall" flex={{ grow: 1 }}>
          {items.map(x => {
            const placedInGallery =
              map("ugc_gallery.id", get("placements", x)).indexOf("510") > -1
            const isSelected =
              (placedInGallery && removed.indexOf(x.id) === -1) ||
              ((x.placement_id || selected.indexOf(x.id) > -1) &&
                removed.indexOf(x.id) === -1)
            return (
              <UgcListItem
                key={x.id}
                altText={x.alt_text}
                filename={get("filename", x)}
                attribution={get("attribution", x)}
                createdAt={get("createdAt", x)}
                imageUrl={get("file.url", x)}
                review={get("review", x)}
                placement_id={get("placement_id", x)}
                relatedProducts={get("related_products", x)}
                replacing={replacing}
                onAction={onAction}
                isSelected={isSelected}
                id={x.id}
              />
            )
          })}
        </Box>
      )}
    </div>
  )
}

UgcList.propTypes = {
  loading: PropTypes.bool,
  items: PropTypes.array,
  replacing: PropTypes.bool,
  onAction: PropTypes.func.isRequired,
  selected: PropTypes.array,
  removed: PropTypes.array,
}

export default memo(UgcList)
