import React from "react"
import PropTypes from "prop-types"
import { Box } from "grommet"
import ImgixImage from "./ImgixImage"
import { get } from "lodash/fp"

const DisplayImageFull = ({ file }) => {
  return (
    <Box width={{ min: "medium" }} height={{ min: "medium" }}>
      <ImgixImage
        fit="contain"
        src={get("url", file)}
        params={{
          w: 800,
          auto: "format",
        }}
        placeholderParams={{ px: 150, w: 400, blur: 400, q: 10, fm: "png" }}
      />
    </Box>
  )
}

DisplayImageFull.propTypes = {
  file: PropTypes.object.isRequired,
}

export default DisplayImageFull
