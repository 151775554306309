import { get, map, isEqual, find, slice, reject, difference } from "lodash/fp"

const swapPlacementUgc = ({
  destinationPlacementId,
  targetPlacementId,
  targetUgc,
  state,
}) => {
  const destinationPlacement = find(
    { id: destinationPlacementId },
    state.chosen
  )
  const targetPlacement = find({ id: targetPlacementId }, state.chosen)

  const restoredRemovedUgc = reject(
    x => isEqual(get("id", targetUgc || get("ugc.id", targetPlacement)), x),
    state.removedUgc
  )

  const newChosen = map(x => {
    if (isEqual(get("id", x), destinationPlacementId)) {
      return {
        ...x,
        ugc: targetUgc || get("ugc", targetPlacement),
        rect: get("rect", targetPlacement) || [],
      }
    }
    if (isEqual(get("id", x), targetPlacementId)) {
      return {
        ...x,
        ugc: get("ugc", destinationPlacement),
        rect: get("rect", destinationPlacement) || [],
      }
    }
    return x
  }, state.chosen)

  return {
    chosen: newChosen,
    removedUgc: [
      ...restoredRemovedUgc,
      ...map("ugc.id", difference(state.chosen, newChosen)),
    ],
  }
}

export default (state, action) => {
  const { type, targetPlacementId, destinationPlacementId, targetUgc } =
    action.payload || {}

  switch (action.type) {
    case "SET_GALLERY_TYPE":
      return { ...state, galleryLayout: action.payload }
    case "DROP_UGC":
      var result = state.chosen

      // Swap UGC
      if (destinationPlacementId === targetPlacementId) {
        return state
      }

      if (state.galleryLayout === "uniform") {
        return {
          ...state,
          chosen: [
            {
              ugc: targetUgc,
              rect: [],
              id: new Date().toString(),
              isNew: true,
            },
            ...state.chosen,
          ],
          removedUgc: reject(
            x => isEqual(get("id", targetUgc), x),
            state.removedUgc
          ),
        }
      }

      if (type === "Ugc") {
        result = swapPlacementUgc({
          destinationPlacementId,
          targetUgc,
          state,
        })
      } else {
        result = swapPlacementUgc({
          destinationPlacementId,
          targetPlacementId,
          state,
        })
      }
      var isNew = get(
        "isNew",
        find({ id: destinationPlacementId }, state.chosen)
      )
      return {
        ...state,
        chosen: result.chosen,
        removedUgc: result.removedUgc,
        updated: isNew
          ? state.updated
          : [...state.updated, targetPlacementId, destinationPlacementId],
      }
    case "REMOVE_UGC":
      var selected = map(x => {
        if (isEqual(get("id", x), action.payload)) {
          return { ...x, ugc: null }
        } else {
          return x
        }
      }, state.chosen)
      return {
        ...state,
        chosen: selected,
        removedUgc: [
          ...state.removedUgc,
          get("ugc.id", find({ id: action.payload }, state.chosen)),
        ],
      }
    case "REMOVE_UGC_PLACEMENT":
      return {
        ...state,
        chosen: reject({ id: action.payload }, state.chosen),
        removed: [...state.removed, action.payload],
        removedUgc: [
          ...state.removedUgc,
          get("ugc.id", find({ id: action.payload }, state.chosen)),
        ],
      }
    case "ADD_TOTAL_LOADED_PLACEMENTS":
      return {
        ...state,
        totalLoadedPlacements: state.totalLoadedPlacements + action.payload,
      }
    case "REPLACE_UGC":
      result = swapPlacementUgc({ ...action.payload, state })
      return {
        ...state,
        chosen: result.chosen,
        removedUgc: result.removedUgc,
        updated: [...state.updated, action.payload.destinationPlacementId],
        replacing: false,
      }
    case "SWAP_UGC":
      result = swapPlacementUgc({
        ...action.payload,
        targetPlacementId: state.replacing,
        state,
      })
      return {
        ...state,
        chosen: result.chosen,
        removedUgc: result.removedUgc,
        updated: [
          ...state.updated,
          state.replacing,
          action.payload.destinationPlacementId,
        ],
        replacing: false,
      }
    case "SAVE_IMAGE_EDITS":
      var croppedIsNew = false
      var revised = state.chosen.map(x => {
        if (isEqual(get("id", x), action.payload.id)) {
          croppedIsNew = x.isNew
          return { ...x, ...action.payload.cropParams }
        } else {
          return x
        }
      })
      return {
        ...state,
        chosen: revised,
        updated: croppedIsNew
          ? state.updated
          : [...state.updated, action.payload.id],
        editing: false,
      }
    case "SET_UGC_QUERY":
      return {
        ...state,
        lastFilename: action.payload.filename,
        lastRelated: action.payload.related_products,
        lastTags: action.payload.tags,
        skipCount: 0,
      }
    case "UPDATE_CHOSEN":
      return {
        ...state,
        totalPlacements: action.payload.totalPlacements,
        totalLoadedPlacements:
          state.totalLoadedPlacements + action.payload.totalLoadedPlacements,
        chosen: [...state.chosen, ...action.payload.chosen],
      }
    case "CLEAR_CHOSEN":
      return { ...state, chosen: [], totalLoadedPlacements: 0 }
    case "INITIALIZE":
      return {
        ...state,
        updated: [],
        removed: [],
        loadedUgc: [],
        selected: [],
        galleryLayout:
          get("payload.galleryLayout", action) || state.galleryLayout,
      }
    case "ADD_MODULE":
      var date = new Date().toString()
      var chosen =
        state.galleryLayout === "alternating_hero"
          ? [
              ...slice(0, action.payload.index, state.chosen),
              { id: `PLACEHOLDER-${date}-1`, isNew: true },
              { id: `PLACEHOLDER-${date}-2`, isNew: true },
              { id: `PLACEHOLDER-${date}-3`, isNew: true },
              { id: `PLACEHOLDER-${date}-4`, isNew: true },
              { id: `PLACEHOLDER-${date}-5`, isNew: true },
              { id: `PLACEHOLDER-${date}-6`, isNew: true },
              { id: `PLACEHOLDER-${date}-7`, isNew: true },
              { id: `PLACEHOLDER-${date}-8`, isNew: true },
              { id: `PLACEHOLDER-${date}-9`, isNew: true },
              ...slice(action.payload.index, state.chosen.length, state.chosen),
            ]
          : [
              ...slice(0, action.payload.index, state.chosen),
              { id: `PLACEHOLDER-${date}-1`, isNew: true },
              { id: `PLACEHOLDER-${date}-2`, isNew: true },
              { id: `PLACEHOLDER-${date}-3`, isNew: true },
              { id: `PLACEHOLDER-${date}-4`, isNew: true },
              { id: `PLACEHOLDER-${date}-5`, isNew: true },
              { id: `PLACEHOLDER-${date}-6`, isNew: true },
              { id: `PLACEHOLDER-${date}-7`, isNew: true },
              ...slice(action.payload.index, state.chosen.length, state.chosen),
            ]
      return {
        ...state,
        chosen: chosen,
        updated: [
          ...state.updated,
          ...map("id", slice(0, action.payload.index, state.chosen)),
        ],
      }
    case "REMOVE_MODULE":
      var componentSize = state.galleryLayout === "salon" ? 7 : 9
      return {
        ...state,
        chosen: [
          ...slice(0, action.payload.index, state.chosen),
          ...slice(
            action.payload.index + componentSize,
            state.chosen.length,
            state.chosen
          ),
        ],
        removedUgc: [
          ...state.removedUgc,
          ...map(
            "id",
            slice(
              action.payload.index,
              action.payload.index + componentSize,
              state.chosen
            )
          ),
        ],
        removed: get("isNew", state.chosen[action.payload.index])
          ? state.removed
          : [
              ...map(
                "id",
                slice(
                  action.payload.index,
                  action.payload.index + componentSize,
                  state.chosen
                )
              ),
            ],
      }
    case "SET_CHOSEN":
      return { ...state, chosen: action.payload }
    case "ADD_TO_CHOSEN":
      return { ...state, chosen: [...state.chosen, ...action.payload] }
    case "ADD_TO_REMOVED":
      return { ...state, removed: [...state.removed, ...action.payload] }
    case "SET_LOADED_UGC":
      return { ...state, loadedUgc: action.payload }
    case "SET_UPDATED":
      return { ...state, updated: action.payload }
    case "SET_REMOVED":
      return { ...state, removed: action.payload }
    case "SET_EDITING":
      return { ...state, editing: action.payload }
    case "SET_REPLACING":
      return { ...state, replacing: action.payload }
    case "SET_LAST_FILENAME":
      return { ...state, lastFilename: action.payload }
    case "SET_LAST_RELATED":
      return { ...state, lastRelated: action.payload }
    case "SET_LAST_TAGS":
      return { ...state, lastTags: action.payload }
    case "SET_SKIP_COUNT":
      return { ...state, skipCount: action.payload }
    case "SET_TOTAL_PLACEMENTS":
      return { ...state, totalPlacements: action.payload }
    case "SET_PLACEMENTS_PAGE":
      return { ...state, placementsPage: action.payload }
    case "NEXT_PAGE":
      return { ...state, skipCount: state.skipCount + state.pageSize }
    case "PREV_PAGE":
      return { ...state, skipCount: state.skipCount - state.pageSize }
    default:
      return state
  }
}

export const initialState = {
  galleryLayout: "uniform",
  editing: null,
  replacing: false,
  chosen: [],
  selected: [],
  removedUgc: [],
  totalLoadedPlacements: 0,
  placementsPage: 1,
  loadedUgc: [],
  updated: [],
  removed: [],
  skipCount: 0,
  totalPlacements: 0,
  pageSize: 108,
}
