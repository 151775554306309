import React, { useMemo, useReducer, useContext, createContext } from 'react';

const ShopifyProductsContext = createContext({});

const ShopifyProductsReducer = (state, action) => {
  const { payload } = action;
  switch (action.type) {
    case 'INITIALIZE':
      return {
        ...state,
        productMap: payload.productMap,
        galleryLayout: payload.galleryLayout
      };
    case 'SET_PRODUCT_MAP':
      return { ...state, productMap: payload };
    default:
      return state;
  }
};

const initialState = {
  productMap: {},
  galleryLayout: 'uniform'
};

function ShopifyProductsProvider(props) {
  const [state, dispatch] = useReducer(ShopifyProductsReducer, initialState);
  const value = useMemo(() => [state, dispatch], [state]);
  return <ShopifyProductsContext.Provider value={value} {...props} />;
}

function useShopifyProducts() {
  const context = useContext(ShopifyProductsContext);
  if (!context) {
    throw new Error('useFilters must be used within a ShopifyProductsProvider');
  }
  const [state, dispatch] = context;
  return {
    state,
    dispatch
  };
}

export { ShopifyProductsProvider, useShopifyProducts };
