import React from "react"
import PropTypes from "prop-types"
import { Image } from "grommet"
import "lazysizes"

import { getClientForUrl, getKeyFromUrl } from "../lib/imgixClient"

const ImgixImage = ({
  src,
  params = {},
  placeholderParams = { px: 50, w: 300, blur: 100, fit: "fill", fm: "png" },
  ...rest
}) => {
  const client = getClientForUrl(src)
  const key = getKeyFromUrl(src)
  return (
    <Image
      fit="cover"
      className="lazyload"
      src={client.buildURL(key, placeholderParams)}
      data-src={client.buildURL(key, params)}
      sizes="100vw"
      attributeConfig={{
        src: "data-src",
        srcSet: "data-srcset",
        sizes: "data-sizes",
      }}
      width="100%"
      {...rest}
    />
  )
}

ImgixImage.propTypes = {
  src: PropTypes.string,
  params: PropTypes.object,
  placeholderParams: PropTypes.object,
}

export default ImgixImage
