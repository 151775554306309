import store from "store"
import { viewingInModalVar, croppingVar, ugcLayoutVar } from "../variables/ui"

export const setViewingInModal = data => {
  viewingInModalVar(data)
}

export const setCropping = data => {
  croppingVar(data)
}

export const setUgcLayout = data => {
  store.set("ugc-layout", data)
  ugcLayoutVar(data)
}
