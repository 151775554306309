import React, { memo, useEffect, useCallback, useMemo } from "react"
import PropTypes from "prop-types"
import { useLazyQuery } from "@apollo/react-hooks"
import { Box, Layer } from "grommet"
import { get, find, isObject, map, difference, compact } from "lodash/fp"
import "lazysizes"
import "lazysizes/plugins/attrchange/ls.attrchange"

import ugcGalleryPlacements from "../../../queries/ugcGalleryPlacements"

import UgcGalleryPreview from "../UgcGalleryPreview"
import UgcGalleryPicker from "../UgcGalleryPicker"
import UgcGalleryImageCropper from "../UgcGalleryImageCropper"
import GalleryPreviewContainer from "../GalleryPreviewContainer"
import { GalleryPickerProvider } from "../UgcGalleryPicker/usePicker"
import { useGalleryAdmin } from "./useGalleryAdmin"
import { useDisplacedUgc } from "../DisplacedUgc/useDisplacedUgc"
import { useShopifyProducts } from "./useShopifyProducts"

const UgcGalleryEditor = memo(
  ({
    onSave,
    lastSavedAt,
    productMap,
    gallery,
    galleryLayout,
    allProducts,
    allTags,
    allColors,
    loading,
  }) => {
    const tagFilters = map(
      x => (isObject(x) ? get("id", x) : x),
      get("tag_filters", gallery) || []
    )
    const {
      dispatch,
      state: {
        totalPlacements,
        totalLoadedPlacements,
        placementsPage,
        editing,
        replacing,
        chosen,
        pageSize,
        removed,
        updated,
        removedUgc,
      },
    } = useGalleryAdmin()

    const { updateDisplacedUgc } = useDisplacedUgc()

    const { dispatch: shopifyDispatch } = useShopifyProducts()

    const itemsInModule = galleryLayout === "salon" ? 7 : 9

    const [getPlacements, { loading: placementsLoading }] = useLazyQuery(
      ugcGalleryPlacements,
      {
        fetchPolicy: "network-only",
        onCompleted: data => {
          dispatch({
            type: "UPDATE_CHOSEN",
            payload: {
              totalPlacements:
                data.ugcGalleryPlacementsConnection.aggregate.count,
              totalLoadedPlacements: data.ugcGalleryPlacementsLookup.length,
              chosen: data.ugcGalleryPlacementsLookup,
            },
          })
        },
      }
    )

    // // Reset change trackers after save
    useEffect(() => {
      dispatch({
        type: "INITIALIZE",
        payload: [],
      })
    }, [lastSavedAt, dispatch])

    // Load More Placements when Page changes
    useEffect(() => {
      if (get("id", gallery)) {
        getPlacements({
          variables: {
            limit: pageSize,
            start: pageSize * (placementsPage - 1),
            galleryId: get("id", gallery),
          },
        })
      }
    }, [pageSize, placementsPage, getPlacements, gallery])

    useEffect(() => {
      shopifyDispatch({
        type: "INITIALIZE",
        payload: { productMap, galleryLayout },
      })
      dispatch({
        type: "SET_GALLERY_TYPE",
        payload: galleryLayout,
      })
    }, [])

    const handleImageAction = (action, params) => {
      switch (action) {
        case "edit":
          dispatch({
            type: "SET_EDITING",
            payload: find({ id: params.id }, chosen),
          })
          break
        case "remove":
          if (galleryLayout === "uniform") {
            dispatch({
              type: "REMOVE_UGC_PLACEMENT",
              payload: params.id,
            })
          } else {
            dispatch({
              type: "REMOVE_UGC",
              payload: params.id,
            })
          }
          break
        case "replace":
          dispatch({
            type: "SET_REPLACING",
            payload: params.id,
          })
          break
        case "cancelReplace":
          dispatch({
            type: "SET_REPLACING",
            payload: false,
          })
          break
        case "confirmReplace":
          dispatch({
            type: "REPLACE_UGC",
            payload: params,
          })
          updateDisplacedUgc({
            displaced: get(
              "ugc",
              find({ id: params.destinationPlacementId }, chosen)
            ),
            placed: params.targetUgc,
          })
          break
        case "swap":
          dispatch({
            type: "SWAP_UGC",
            payload: params,
          })
          break
        case "drop":
          dispatch({
            type: "DROP_UGC",
            payload: {
              destinationPlacementId: params.id,
              targetPlacementId:
                params.item.type === "Ugc" ? null : params.item.id,
              targetUgc: params.item.ugc,
              type: params.item.type,
            },
          })
          if (
            galleryLayout === "alternating_hero" &&
            params.item.type === "Ugc" &&
            params.id
          ) {
            updateDisplacedUgc({
              displaced: get("ugc", find({ id: params.id }, chosen)),
              placed: params.item.ugc,
            })
          }
          break
        case "addModule":
          dispatch({
            type: "ADD_MODULE",
            payload: {
              position: params.position,
              index:
                chosen.indexOf(params.target) +
                (params.position === "below" ? itemsInModule : 0),
            },
          })
          break
        case "removeModule":
          dispatch({
            type: "REMOVE_MODULE",
            payload: {
              index: chosen.indexOf(params.target),
            },
          })
          break
        default:
          break
      }
    }

    const handleImageCrop = useCallback(
      (id, cropParams) => {
        if (!id) {
          dispatch({
            type: "SET_EDITING",
            payload: false,
          })
        } else {
          dispatch({
            type: "SAVE_IMAGE_EDITS",
            payload: { id, cropParams },
          })
        }
      },
      [dispatch]
    )

    const handleSave = useCallback(async () => {
      await onSave({
        removed,
        updated: updated,
        items: chosen,
        galleryId: get("id", gallery),
        totalPlacements,
      })
      dispatch({ type: "CLEAR_CHOSEN" })
      dispatch({ type: "INITIALIZE", payload: { galleryLayout } })
      getPlacements({
        variables: {
          limit: pageSize,
          start: 0,
          galleryId: get("id", gallery),
        },
      })
      dispatch({
        type: "SET_PLACEMENTS_PAGE",
        payload: 1,
      })
    }, [chosen, gallery, removed, updated, totalPlacements])

    const handleLoadMorePlacements = () => {
      dispatch({
        type: "SET_PLACEMENTS_PAGE",
        payload: placementsPage + 1,
      })
    }

    const handleJumpToBottom = useCallback(async () => {
      await getPlacements({
        variables: {
          limit: undefined,
          start: pageSize * (placementsPage - 1),
          galleryId: get("id", gallery),
        },
      })
    })

    const chosenUgc = useMemo(() => {
      return difference(compact(map("ugc.id", chosen)), removedUgc)
    }, [chosen, removedUgc])

    useEffect(() => {
      dispatch({
        type: "SET_PLACEMENTS_PAGE",
        payload: 1,
      })
      dispatch({ type: "CLEAR_CHOSEN" })
    }, [gallery])

    return (
      <Box className="editor-container">
        {editing && (
          <Layer full={true}>
            <UgcGalleryImageCropper item={editing} onCrop={handleImageCrop} />
          </Layer>
        )}
        <Box>
          <Box direction="row">
            <Box
              flex={{ shrink: 0, grow: 0 }}
              width="400px"
              id="picker"
              background="light-3"
            >
              {gallery && (
                <GalleryPickerProvider>
                  <UgcGalleryPicker
                    handleImageAction={handleImageAction}
                    replacing={replacing}
                    tagFilters={tagFilters}
                    galleryId={gallery.id}
                    chosenUgc={chosenUgc}
                    removedUgc={removedUgc}
                    title={get("title", gallery)}
                    allProducts={allProducts}
                    allTags={allTags}
                    allColors={allColors}
                  />
                </GalleryPickerProvider>
              )}
            </Box>
            <Box overflow="hidden" flex={{ grow: 1, shrink: 1 }}>
              <GalleryPreviewContainer
                onAction={handleImageAction}
                galleryLayout={galleryLayout}
              >
                {gallery && (
                  <UgcGalleryPreview
                    items={chosen}
                    onAction={handleImageAction}
                    onLoadMore={handleLoadMorePlacements}
                    onJumpToBottom={handleJumpToBottom}
                    hasMore={totalLoadedPlacements < totalPlacements}
                    loading={placementsLoading || loading}
                    replacing={replacing}
                    galleryLayout={galleryLayout}
                    handleSave={handleSave}
                  />
                )}
              </GalleryPreviewContainer>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }
)

UgcGalleryEditor.propTypes = {
  onSave: PropTypes.func.isRequired,
  lastSavedAt: PropTypes.string,
  productMap: PropTypes.object,
  gallery: PropTypes.string,
  galleryLayout: PropTypes.string,
  allProducts: PropTypes.array,
  allTags: PropTypes.array,
  allColors: PropTypes.array,
  loading: PropTypes.bool,
}

export default memo(UgcGalleryEditor)
